<template>
  <div>
    <v-row
      no-gutters
      class="my-4"
    >
      <v-dialog
        v-model="createDatasetDialog"
        width="40vw"
      >
        <template #activator="{ on }">
          <v-btn
            color="secondary"
            class="ml-3"
            v-on="on"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Create New Dataset
          </v-btn>
        </template>

        <v-card>
          <v-card-title>Create New Dataset</v-card-title>
          <v-card-text>
            <v-form v-model="createDatasetFormValid">
              <v-text-field
                v-model="newDataset.name"
                label="Name"
                :rules="[nonEmptyRule]"
                :error-messages="newDataset.errorMessages"
                @input="newDataset.errorMessages = []"
              />
              <v-textarea
                v-model="newDataset.desc"
                label="Description (Optional)"
              />
              <v-switch
                v-model="newDataset.public"
                label="Public"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              :disabled="!createDatasetFormValid"
              @click="createDataset"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row
      no-gutters
      align="center"
      class="px-2"
    >
      <v-tabs v-model="datasetTab">
        <v-tab>
          My Datasets
          <!-- Edit -->
          <!-- TODO: Move to tab content -->
          <v-btn
            v-if="!editDatasets"
            class="ml-1"
            color="primary"
            icon
            small
            @click="editDatasets = true"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-else
            class="ml-1"
            icon
            small
            color="primary"
            @click="editDatasets = false"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-tab>
        <v-tab>Shared Datasets</v-tab>
        <v-tab>Public Datasets</v-tab>
        <v-btn
          icon
          class="mt-1"
          title="Refresh Datasets"
          @click="refreshCurrentDatasets"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-tabs>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-data-table
          :loading="loading"
          item-key="id"
          :items="currentDatasets"
          :headers="headers"
          height="60vh"
        >
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template #item.name="{ item }">
            <v-menu v-if="editDatasets && datasetTab == 0">
              <template #activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  v-on="on"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-subtitle>Are you sure you want to delete this dataset?</v-card-subtitle>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="secondary">
                    Cancel
                  </v-btn>
                  <v-btn
                    color="error"
                    @click="deleteDataset(item.id)"
                  >
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
            <router-link :to="`dataset/${item.id}`">
              {{ item.name }}
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getPaginated } from '@/utils';

const headers = [
  {
    text: 'Name',
    value: 'name',
  },
  {
    text: 'Description',
    value: 'description',
  },
];

export default {
  inject: ['axios'],
  data() {
    return {
      headers,
      datasetTab: 0,
      datasets: {
        owned: [],
        shared: [],
        public: [],
      },
      loading: true,
      editDatasets: false,

      // Dataset creation
      newDataset: {
        name: '',
        desc: '',
        public: false,
        errorMessages: [],
      },
      createDatasetDialog: false,
      createDatasetFormValid: false,
      nonEmptyRule: (val) => !!val,
    };
  },
  computed: {
    currentDatasets() {
      switch (this.datasetTab) {
        case 2:
          return this.datasets.public;

        case 1:
          return this.datasets.shared;

        case 0:
        default:
          return this.datasets.owned;
      }
    },
  },
  async mounted() {
    this.fetchDatasets();
  },
  methods: {
    async fetchDatasets() {
      this.loading = true;

      const [owned, shared, pub] = await Promise.all([
        getPaginated(this.axios, 'datasets', { params: { access: 'owned' } }),
        getPaginated(this.axios, 'datasets', { params: { access: 'shared' } }),
        getPaginated(this.axios, 'datasets', { params: { access: 'public' } }),
      ]);
      this.datasets.owned = owned;
      this.datasets.shared = shared;
      this.datasets.public = pub;

      this.loading = false;
    },

    async refreshCurrentDatasets() {
      this.loading = true;
      switch (this.datasetTab) {
        case 0:
          this.datasets.owned = (await getPaginated(this.axios, 'datasets', { params: { access: 'owned' } }));
          break;

        case 1:
          this.datasets.shared = (await getPaginated(this.axios, 'datasets', { params: { access: 'shared' } }));
          break;

        case 2:
          this.datasets.public = (await getPaginated(this.axios, 'datasets', { params: { access: 'public' } }));
          break;

        default:
          break;
      }
      this.loading = false;
    },
    async createDataset() {
      try {
        const resp = await this.axios.post('datasets', {
          name: this.newDataset.name,
          description: this.newDataset.desc,
          public: this.newDataset.public,
        });

        // Close dialog on success
        this.createDatasetDialog = false;

        // Navigate to page
        const datasetId = resp.data.id;
        this.$router.push(`/dataset/${datasetId}`);
      } catch (error) {
        this.newDataset.errorMessages = error.response.data;
      }
    },
    async deleteDataset(id) {
      await this.axios.delete(`datasets/${id}`);
      this.fetchDatasets();
    },
  },
};
</script>
