import { render, staticRenderFns } from "./SliceViewer.vue?vue&type=template&id=787290bc&scoped=true&"
import script from "./SliceViewer.vue?vue&type=script&lang=ts&"
export * from "./SliceViewer.vue?vue&type=script&lang=ts&"
import style0 from "./SliceViewer.vue?vue&type=style&index=0&id=787290bc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "787290bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VRow,VSlider})
