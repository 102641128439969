import { render, staticRenderFns } from "./LaunchButtons.vue?vue&type=template&id=9d9e2df8&scoped=true&"
var script = {}
import style0 from "./LaunchButtons.vue?vue&type=style&index=0&id=9d9e2df8&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d9e2df8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
