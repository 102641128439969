/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */

// @ts-ignore
import vtk2DView from '@kitware/vtk.js/Proxy/Core/View2DProxy';
// @ts-ignore
import vtkSourceProxy from '@kitware/vtk.js/Proxy/Core/SourceProxy';
// @ts-ignore
import vtkSliceRepresentationProxy from '@kitware/vtk.js/Proxy/Representations/SliceRepresentationProxy';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { InterpolationType } from '@kitware/vtk.js/Rendering/Core/ImageProperty/Constants';

import { SliceAxis } from '@/types/vtk';

function createProxyDefinition(
  classFactory: any,
  ui: any = [],
  links: any = [],
  definitionOptions: any = {},
  props: any = {},
) {
  return {
    class: classFactory,
    options: { links, ui, ...definitionOptions },
    props,
  };
}

const View2DUI = [
  { name: 'name' },
  {
    name: 'background',
    domain: {
      palette: [],
    },
  },
  {
    name: 'orientationAxesVisibility',
  },
  {
    name: 'presetToOrientationAxes',
    domain: {
      items: [
        { text: 'XYZ', value: 'default' },
        { text: 'LPS', value: 'lps' },
      ],
    },
  },
  {
    name: 'annotationOpacity',
    domain: { min: 0, max: 1, step: 0.01 },
  },
];

const SliceUI = [
  {
    name: 'visibility',
  },
  {
    name: 'windowWidth',
    domain: { min: 0, max: 255, step: 0.01 },
  },
  {
    name: 'windowLevel',
    domain: { min: 0, max: 255, step: 0.01 },
  },
  {
    name: 'slice',
    domain: { min: 0, max: 255, step: 1 },
  },
];

const SliceProxyLink = [
  { link: 'Visibility', property: 'visibility', updateOnBind: true },
  { link: 'WW', property: 'windowWidth', updateOnBind: true },
  { link: 'WL', property: 'windowLevel', updateOnBind: true },
];

export const proxyConfiguration = {
  definitions: {
    // Proxy: {
    //   LookupTable: createProxyDefinition(vtkLookupTableProxy, [], [], {
    //     presetName: 'Default (Cool to Warm)',
    //   }),
    //   PiecewiseFunction: createProxyDefinition(vtkPiecewiseFunctionProxy),
    // },
    Sources: {
      TrivialProducer: createProxyDefinition(vtkSourceProxy),
    },
    Representations: {
      SliceX: createProxyDefinition(
        vtkSliceRepresentationProxy,
        SliceUI,
        [
          { link: 'SliceX', property: 'slice', updateOnBind: true },
          ...SliceProxyLink,
        ],
      ),
      SliceY: createProxyDefinition(
        vtkSliceRepresentationProxy,
        SliceUI,
        [
          { link: 'SliceY', property: 'slice', updateOnBind: true },
          ...SliceProxyLink,
        ],
      ),
      SliceZ: createProxyDefinition(
        vtkSliceRepresentationProxy,
        SliceUI,
        [
          { link: 'SliceZ', property: 'slice', updateOnBind: true },
          ...SliceProxyLink,
        ],
      ),
    },
    Views: {
      View2D_X: createProxyDefinition(vtk2DView, View2DUI, { axis: 0 }),
      View2D_Y: createProxyDefinition(vtk2DView, View2DUI, { axis: 1 }),
      View2D_Z: createProxyDefinition(vtk2DView, View2DUI, { axis: 2 }),
    },
  },
  representations: {
    View2D_X: { vtkImageData: { name: 'SliceX' } },
    View2D_Y: { vtkImageData: { name: 'SliceY' } },
    View2D_Z: { vtkImageData: { name: 'SliceZ' } },
  },
};

export const AxisMap = {
  X: {
    axis: 0,
    viewUp: [0, 0, 1],
    directionOfProjection: [1, 0, 0],
  },
  Y: {
    axis: 1,
    viewUp: [0, 0, 1],
    directionOfProjection: [0, -1, 0],
  },
  Z: {
    axis: 2,
    viewUp: [0, -1, 0],
    directionOfProjection: [0, 0, -1],
  },
};

type ViewType = 'View2D_Z' | 'View2D_Y' | 'View2D_X';
type ViewTypeTuple = [ViewType, SliceAxis];
export function getView(proxyManager: any, viewType: ViewTypeTuple) {
  const [type, name] = viewType;
  let view: any = null;
  const views = proxyManager.getViews();
  for (let i = 0; i < views.length; i += 1) {
    if (views[i].getProxyName() === type) {
      if (name) {
        if (views[i].getName() === name) {
          view = views[i];
        }
      } else {
        view = views[i];
      }
    }
  }

  if (!view) {
    view = proxyManager.createProxy('Views', type, { name });

    // Make sure represention is created for new view
    proxyManager
      .getSources()
      .forEach((s: any) => proxyManager.getRepresentation(s, view));

    // Update orientation
    //   LPS is the default of the view constructor
    //   Camera initialization when the view is rendered will override this
    //   with the project's preferred orientation
    const { axis, directionOfProjection, viewUp } = AxisMap[name];
    view.updateOrientation(axis, directionOfProjection, viewUp);

    // set background to transparent
    view.setBackground(0, 0, 0, 255);

    // FIXME: Use storage to choose defaults
    view.setPresetToOrientationAxes('default');
  }

  return view;
}

const viewTypeTuples: [ViewType, SliceAxis][] = [['View2D_Z', 'Z'], ['View2D_Y', 'Y'], ['View2D_X', 'X']];

/**
 * Ensures that the passed in proxy manager has a 2D slice view for each axis setup.
 * @param proxyManager The proxy manager to configure
 */
export function setupProxyManagerViews(proxyManager: any): any[] {
  if (!proxyManager.getViews().length) {
    viewTypeTuples.forEach((type) => {
      const view = getView(proxyManager, type);
      view.setOrientationAxesVisibility(false);
      view.getRepresentations().forEach((representation: any) => {
        representation.setInterpolationType(InterpolationType.NEAREST);
        representation.onModified(() => {
          view.render(true);
        });
      });
    });
  }

  return proxyManager.getViews();
}

/**
 * Scales the camera to fill the view (zoom to fill)
 */
export function fill2DView(view: any, resize = true) {
  if (resize) view.resize();

  const viewName = view.getName();
  if (viewName === 'default') return 0;

  const bounds = view.getRenderer().computeVisiblePropBounds();
  const dim = [
    (bounds[1] - bounds[0]) / 2,
    (bounds[3] - bounds[2]) / 2,
    (bounds[5] - bounds[4]) / 2,
  ];
  const w = view.getContainer().clientWidth;
  const h = view.getContainer().clientHeight;
  const r = w / h;

  let x;
  let y;
  if (viewName === 'X') {
    [, x, y] = dim;
  } else if (viewName === 'Y') {
    [x, , y] = dim;
  } else if (viewName === 'Z') {
    [x, y] = dim;
  } else {
    throw new Error('View not found!');
  }

  let scale;
  if (r >= x / y) {
    scale = y + 1;
  } else {
    scale = x / r + 1;
  }
  if (resize) {
    view.resize();
    view.getCamera().setParallelScale(scale);
  }
  return scale;
}
