<template>
  <section>
    <v-app-bar
      color="primary"
      dark
    >
      <HomeLogo to="/" />
      <v-spacer />

      <v-btn
        href="https://github.com/KitwareMedical/UTM"
        target="_blank"
        text
      >
        <v-icon>mdi-github</v-icon>
        <span class="mr-2">View in github</span>
      </v-btn>
    </v-app-bar>
    <v-sheet
      sm="12"
      md="12"
      class="cover-bg"
    >
      <v-row justify="center">
        <v-col
          sm="7"
          md="7"
          class="pl-12 ml-12"
        >
          <h1 class="display-2 font-weight-Medium white--text mb-8">
            UTM Web Tool
          </h1>
          <h1 class="display-1 font-weight-light condensed white--text mb-5">
            User Guide
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
          sm="7"
          md="7"
          class="pl-12 ml-12"
        />
      </v-row>
    </v-sheet>
  </section>
</template>

<script>
import HomeLogo from '@/components/HomeLogo.vue';

export default {
  components: {
    HomeLogo,
  },
};
</script>

<style lang="css" scoped>
.cover-bg {
  background: linear-gradient(90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
    url("../assets/jacobian_inspect.png") no-repeat 10% 60%;

  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>
