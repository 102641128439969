/* eslint-disable import/prefer-default-export */
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Paginated, Status } from '@/types';

export const sleep = (ms: number) => new Promise((resolve) => { setTimeout(resolve, ms); });

/**
 * Get all values from a paginated endpoint.
 */
// eslint-disable-next-line max-len
export async function getPaginated<T = unknown>(axios: AxiosInstance, url: string, config?: AxiosRequestConfig): Promise<T[]> {
  const mergedConfig = { ...config, params: { ...config?.params, limit: 1000 } };
  const initialResp: Paginated<T> = (await axios.get(url, mergedConfig)).data;

  let { results, next } = initialResp;
  while (next !== null) {
    // eslint-disable-next-line no-await-in-loop
    const { data } = (await axios.get(next)) as AxiosResponse<Paginated<T>>;
    next = data.next;
    results = results.concat(data.results);
  }

  return results;
}

export function statusToIcon(status: Status): [string, string | undefined] {
  switch (status) {
    case 'Pending':
      return ['mdi-pause', undefined];
    case 'Running':
      return ['mdi-progress-clock', 'primary'];
    case 'Finished':
      return ['mdi-check-circle', 'success'];
    // Failed
    default:
      return ['mdi-close-circle', 'error'];
  }
}
