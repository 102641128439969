














































































































































































/* eslint-disable camelcase */
import {
  computed,
  defineComponent, inject, onMounted, ref, toRefs, watch,
} from '@vue/composition-api';
import { AxiosInstance } from 'axios';

import { getPaginated, statusToIcon } from '@/utils';
import SegmentedImageViewer from '@/components/Preprocessed/SegmentedImageViewer.vue';
import JacobianImageViewer from '@/components/Preprocessed/JacobianImageViewer.vue';
import FeatureImageViewer from '@/components/Preprocessed/FeatureImageViewer.vue';
import RegisteredImageViewer from '@/components/Preprocessed/RegisteredImageViewer.vue';
import {
  Atlas, Dataset, Paginated, PreprocessingBatch,
} from '@/types';

interface BaseImage {
  id: number;
  created: string;
  modified: string;
  blob: string;
}

interface RegisteredImage extends BaseImage {
  registration_type: string;
}

interface FeatureImage extends BaseImage {
  downsample_factor: string;
}

interface ImageGroup {
  id: number;
  name: string;
  type: string;
  dataset: number;
  patient: string;
  metadata: Record<string, unknown>;

  // PreprocessedImages
  registered: RegisteredImage | null;
  jacobian: BaseImage | null;
  segmented: BaseImage | null;
  feature: FeatureImage | null;
}

type ImageType = 'registered' | 'jacobian' | 'segmented' | 'feature';
const imageTypes: ImageType[] = ['registered', 'jacobian', 'segmented', 'feature'];
const imageTypeTextMap = {
  registered: 'Registration',
  segmented: 'Segmentation',
  feature: 'Feature Image',
};

const headers = [
  { text: 'ID', value: 'id' },
  { text: 'Name', value: 'name' },
  { text: 'Preprocessed Images', value: 'images' },
  { text: 'Dataset', value: 'dataset' },
];

function batchRunning(batch: PreprocessingBatch) {
  return ['Pending', 'Running'].includes(batch.status);
}

export default defineComponent({
  name: 'Preprocessing',
  components: {
    SegmentedImageViewer,
    JacobianImageViewer,
    FeatureImageViewer,
    RegisteredImageViewer,
  },
  props: {
    datasetId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const axios = inject('axios') as AxiosInstance;
    const { datasetId } = toRefs(props);

    // Dataset
    const dataset = ref<Dataset | null>(null);
    const loading = ref(false);
    const visualizationDialog = ref(false);
    const preprocessingMode = ref<string | null>(null);
    const preprocessingItems = [
      { text: 'ANTS' },
      { text: 'FreeSurfer', disabled: true },
    ];

    // Batch
    const pollInterval = ref<number>();
    const preprocessingBatchId = computed(() => dataset.value?.current_preprocessing_batch?.id);
    const currentBatch = ref<PreprocessingBatch>();
    const preprocessingRunning = computed(() => (
      currentBatch.value === undefined
        ? false
        : batchRunning(currentBatch.value)
    ));
    const showBatchErrorMessage = ref(false);
    const statusIcons = computed(() => {
      if (currentBatch.value === undefined) {
        return [undefined, undefined];
      }

      return statusToIcon(currentBatch.value.status);
    });
    async function fetchPreprocessingBatch() {
      loading.value = true;

      // Fetch and check if finished
      const batch: PreprocessingBatch = (await axios.get(`preprocessing_batches/${preprocessingBatchId.value}`)).data;
      if (!batchRunning(batch)) {
        clearInterval(pollInterval.value);
        pollInterval.value = undefined;
      }

      // Set dataset value
      currentBatch.value = batch;
      loading.value = false;
    }

    // Images
    const images = ref<ImageGroup[]>([]);
    const selectedImage = ref<ImageGroup | null>(null);
    const selectedImageType = ref<ImageType | null>(null);

    // For images that need the t1 atlas
    const t1Atlas = ref<Atlas>();
    async function fetchT1Atlas() {
      const resp = await axios.get<Paginated<Atlas>>('atlases', { params: { name: 'T1.nii.gz' } });
      if (resp.data.count === 0) {
        throw new Error('Could not fetch T1 Atlas.');
      }

      [t1Atlas.value] = resp.data.results;
    }

    // Fetch t1 atlas when component is created
    fetchT1Atlas();

    // Fetch all images and set results
    async function fetchPreprocessedImages() {
      loading.value = true;
      images.value = await getPaginated(axios, `preprocessing_batches/${preprocessingBatchId.value}/images`);
      loading.value = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function setSelectedImage(val: ImageGroup, type: ImageType) {
      selectedImage.value = val;
      selectedImageType.value = type;

      visualizationDialog.value = true;
    }

    function disablePreprocessVis(im: ImageGroup) {
      return imageTypes.every((type) => im[type] === null);
    }

    async function poll() {
      fetchPreprocessedImages();
      fetchPreprocessingBatch();
    }

    function startPolling() {
      pollInterval.value = setInterval(poll, 10_000);
      poll();
    }

    // Call start polling once batch id is known
    watch(preprocessingBatchId, () => {
      startPolling();
    });

    async function fetchDataset() {
      loading.value = true;
      dataset.value = (await axios.get(`datasets/${datasetId.value}`)).data;
      loading.value = false;
    }

    async function run() {
      await axios.post(`datasets/${datasetId.value}/preprocess`);

      // Re-fetch dataset to retrieve current preprocessing batch
      fetchDataset();
    }

    onMounted(() => {
      fetchDataset();
    });

    return {
      dataset,
      currentBatch,
      showBatchErrorMessage,
      preprocessingRunning,
      statusIcons,
      preprocessingBatchId,
      images,
      imageTypes,
      imageTypeTextMap,
      headers,
      loading,
      preprocessingMode,
      preprocessingItems,
      visualizationDialog,
      selectedImage,
      selectedImageType,
      disablePreprocessVis,
      t1Atlas,

      run,
      fetchPreprocessedImages,
      setSelectedImage,
    };
  },
});
