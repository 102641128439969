import { render, staticRenderFns } from "./SegmentedImageViewer.vue?vue&type=template&id=6cdfbc9e&scoped=true&"
import script from "./SegmentedImageViewer.vue?vue&type=script&lang=ts&"
export * from "./SegmentedImageViewer.vue?vue&type=script&lang=ts&"
import style0 from "./SegmentedImageViewer.vue?vue&type=style&index=0&id=6cdfbc9e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cdfbc9e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VRow,VSubheader})
