



































































































// Dataset components
import {
  computed,
  defineComponent, inject, onMounted, ref, watch,
} from '@vue/composition-api';
import type { AxiosInstance } from 'axios';

import { Dataset } from '@/types';
import CreateUploadBatch from './components/CreateUploadBatch.vue';
import PendingUploads from './components/PendingUploads.vue';
import DatasetImages from './components/DatasetImages.vue';
import Preprocessing from './components/Preprocessing.vue';
import Analysis from './components/Analysis.vue';
import Access from './components/Access.vue';

const DEFAULT_TAB = 'images';

export default defineComponent({
  components: {
    CreateUploadBatch,
    PendingUploads,
    DatasetImages,
    Preprocessing,
    Analysis,
    Access,
  },
  props: {
    datasetId: {
      required: true,
      type: String,
    },
  },
  setup(props, ctx) {
    const axios = inject<AxiosInstance>('axios');
    if (axios === undefined) {
      throw new Error('Must provide "axios" into component.');
    }

    const router = ref(ctx.root.$router);
    const route = computed(() => router.value.currentRoute);

    const dataset = ref<Dataset | null>(null);
    const loading = ref(true);
    const writeAccess = ref(false);
    const adminAccess = ref(false);
    const hideUploadImages = ref(true);
    const noImages = ref(true);
    const hidePreprocessed = ref(true);
    const hideAnalysis = ref(true);
    const tab = ref(DEFAULT_TAB);

    // Update route if tab changes
    watch(tab, (val) => {
      if (route.value.query.tab !== val) {
        router.value.replace({ query: { tab: val } });
      }
    });

    // Update tab if route changes
    watch(route, (currentRoute) => {
      const { query } = currentRoute;
      const tabQuery = Array.isArray(query.tab) ? query.tab[0] : query.tab;
      if (tabQuery !== null && tab.value !== tabQuery) {
        tab.value = tabQuery;
      }
    }, { immediate: true });

    async function fetchDataset() {
      loading.value = true;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const resp = await axios!.get(`datasets/${props.datasetId}`);
      dataset.value = resp.data as Dataset;

      // Set access values
      writeAccess.value = (dataset.value.access !== null);
      adminAccess.value = (dataset.value.access === 'owner');

      // Hide tabs if needed
      hideUploadImages.value = !dataset.value.uploads_active;
      noImages.value = dataset.value.image_count === 0;
      hidePreprocessed.value = !dataset.value.image_count;
      hideAnalysis.value = dataset.value.current_preprocessing_batch?.status !== 'Finished';

      loading.value = false;
    }

    onMounted(() => {
      fetchDataset();
    });

    return {
      route,
      dataset,
      loading,
      writeAccess,
      adminAccess,
      noImages,
      hideUploadImages,
      hidePreprocessed,
      hideAnalysis,
      tab,
    };
  },
});
