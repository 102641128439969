




























/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  ref, toRefs, onMounted, onBeforeUnmount,
  defineComponent,
  computed,
  PropType,
} from '@vue/composition-api';

import '@kitware/vtk.js/Rendering/Profiles/Geometry';

// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Volume';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';
import type { Bounds } from '@kitware/vtk.js/types';

// @ts-ignore
import { InterpolationType } from '@kitware/vtk.js/Rendering/Core/ImageProperty/Constants';

import type { SliceAxis } from '@/types/vtk';
import { AxisMap, fill2DView } from './proxy';
import CrosshairSet, { ColoredCrosshairLine } from './crosshairs';
import { useLocation, axisNumberToIJK, IJKToXYZ } from './state';

export default defineComponent({
  name: 'SliceViewer',
  props: {
    view: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: Object as any,
      required: true,
    },
    proxyManager: {
      type: Object,
      required: true,
    },
    colorBounds: {
      type: Object as PropType<[number, number]>,
      required: true,
    },
  },
  setup(props) {
    const { view } = toRefs(props);
    const axisName = computed(() => {
      switch (view.value.getAxis()) {
        // X
        case 0:
          return 'Sagittal';
        // Y
        case 1:
          return 'Coronal';
        // Z
        case 2:
        default:
          return 'Axial';
      }
    });

    const crosshairCanvasId = `crosshairs-${axisName.value}`;
    const vtkContainer = ref<HTMLElement | null>(null);
    const crosshairCanvas = ref<HTMLCanvasElement | null>(null);

    // Import the slice state/functions
    const {
      iSlice,
      jSlice,
      kSlice,
      setLocation,
      IJKToSliceRef,
      IJKToRepresentation,
      setSlice,
    } = useLocation(props.proxyManager);
    const axis = axisNumberToIJK(view.value.getAxis());
    const slice = IJKToSliceRef(axis);
    const imageRepresentation = IJKToRepresentation(axis)[0];

    function render() {
      view.value.getRenderer().getRenderWindow().render();
    }

    function initView() {
      view.value.setContainer(vtkContainer.value);
    }

    function initCamera() {
      const camera = view.value.getCamera();
      const viewName = view.value.getName() as SliceAxis;
      camera.setDirectionOfProjection(...AxisMap[viewName].directionOfProjection);
      camera.setViewUp(AxisMap[viewName].viewUp);

      // Set camera origentation and scale view
      view.value.resetCamera();
      fill2DView(view.value);
    }

    const sliceBounds = ref([0, 100]);
    function initSlice() {
      const bounds: Bounds = imageRepresentation.getBounds();
      const axisNum: 0 | 1 | 2 = view.value.getAxis();

      // Bounds is [xmin, xmax, ymin, ymax, zmin, zmax]
      const min = Math.floor(bounds[axisNum * 2]);
      const max = Math.ceil(bounds[(axisNum * 2) + 1]);
      sliceBounds.value = [min, max];

      // Set slice to middle
      const midSlice = Math.round((max - min) / 2) + min;
      setSlice(axis, midSlice);
    }

    function updateSlice(val: number) {
      setSlice(axis, val);
    }

    function colorImageSlice() {
      const rgb = vtkColorTransferFunction.newInstance();
      const epsilon = 0.000000001;

      // Negative values are red, positive values are blue
      rgb.addRGBPoint(-1 * epsilon, 1, 0, 0);
      rgb.addRGBPoint(0, 0, 0, 0);
      rgb.addRGBPoint(epsilon, 0, 0, 1);
      imageRepresentation.getActors()[0].getProperty().setUseLookupTableScalarRange(true);
      imageRepresentation.getActors()[0].getProperty().setRGBTransferFunction(0, rgb);
      imageRepresentation.setInterpolationType(InterpolationType.NEAREST);

      // Set opacity to scale linearly from each size of zero
      const opFun = vtkPiecewiseFunction.newInstance();
      opFun.addPoint(props.colorBounds[0], 1);
      opFun.addPoint(0, 0);
      opFun.addPoint(props.colorBounds[1], 1);
      imageRepresentation.getActors()[0].getProperty().setPiecewiseFunction(0, opFun);
    }

    function placeCrosshairs(clickEvent: MouseEvent) {
      const crosshairSet = new CrosshairSet(
        imageRepresentation,
        view.value,
        null,
        iSlice.value,
        jSlice.value,
        kSlice.value,
      );
      const location = crosshairSet.locationOfClick(clickEvent);
      if (location) {
        setLocation(location);
      }
    }

    const showCrosshairs = true;
    function updateCrosshairs() {
      const myCanvas = crosshairCanvas.value;
      if (myCanvas === null) {
        throw new Error('Crosshair Canvas is null!');
      }

      if (!showCrosshairs) {
        return;
      }

      const ctx = myCanvas.getContext('2d');
      if (!ctx) {
        return;
      }

      ctx.clearRect(0, 0, myCanvas.width, myCanvas.height);
      const crosshairSet = new CrosshairSet(
        imageRepresentation,
        view.value,
        myCanvas,
        iSlice.value,
        jSlice.value,
        kSlice.value,
      );
      const colors = {
        x: '#fdd835',
        y: '#4caf50',
        z: '#b71c1c',
      };

      function drawLine(displayLine: ColoredCrosshairLine) {
        if (!displayLine || !ctx) {
          return;
        }

        ctx.strokeStyle = displayLine.color;
        ctx.beginPath();
        ctx.moveTo(...displayLine.start);
        ctx.lineTo(...displayLine.end);
        ctx.stroke();
      }

      // eslint-disable-next-line max-len
      const [displayLine1, displayLine2] = crosshairSet.getCrosshairsForAxis(IJKToXYZ(axis), colors);
      drawLine(displayLine1);
      drawLine(displayLine2);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let renderSubscription: any;
    let resizeObserver: ResizeObserver;
    function initCrosshairs() {
      // Check container
      if (vtkContainer.value === null) {
        throw new Error('Viewer Container is null!');
      }

      // Update crosshairs any time the view is rendered
      renderSubscription = view.value.getInteractor().onRenderEvent(() => {
        updateCrosshairs();
      });

      // Resize canvas any time viewer is resized
      resizeObserver = new window.ResizeObserver((entries) => {
        if (entries.length === 1 && vtkContainer.value && crosshairCanvas.value) {
          // Set width/height of crosshair canvas to match viewer container
          const width = vtkContainer.value.clientWidth;
          const height = vtkContainer.value.clientHeight;
          crosshairCanvas.value.width = width;
          crosshairCanvas.value.height = height;
          crosshairCanvas.value.style.width = `${width}px`;
          crosshairCanvas.value.style.height = `${height}px`;
          updateCrosshairs();
        }
      });
      resizeObserver.observe(vtkContainer.value);

      // When left mouse button is clicked, place crossharis at location
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      view.value.getInteractor().onLeftButtonPress((event: any) => placeCrosshairs(event));

      // Draw crosshairs for the first time
      updateCrosshairs();
    }

    function init() {
      initView();
      initSlice();
      initCrosshairs();
      colorImageSlice();
      initCamera();
      render();
    }

    function update() {
      setSlice(axis, slice.value);
      colorImageSlice();
      render();
    }

    // Call update every time dataset is updated
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const imageSource = props.proxyManager.getSources().find((s: any) => s.getName() === 'Image');
    imageSource.onDatasetChange(() => {
      update();
    });

    // Init must be done on mount, so the viewer ref is available
    onMounted(() => {
      init();
    });

    onBeforeUnmount(() => {
      renderSubscription.unsubscribe();
      resizeObserver.unobserve(vtkContainer.value as HTMLElement);

      // Remove viewer from HTMLElement on unmount
      view.value.setContainer(null);
    });

    return {
      vtkContainer,
      crosshairCanvas,
      crosshairCanvasId,
      sliceBounds,
      updateSlice,
      slice,
      axis,
      axisName,
    };
  },
});
