<template>
  <div>
    <v-btn
      :to="`/dataset/${datasetId}`"
      exact
      class="my-2"
    >
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Back to dataset
    </v-btn>
    <v-card>
      <girder-upload
        :dest="{ batchId, fieldId: 'core.Image.blob' }"
        :upload-cls="FileUploadHandler"
        :max-show="5"
        @done="uploadDone"
      >
        <template #header>
          <div />
        </template>
      </girder-upload>
      <div class="title px-3 mt-3">
        Images ready to be uploaded
        <span v-if="!loading">({{ count }})</span>
      </div>
      <div class="text--secondary px-3 mt-3">
        By clicking on the box above, or dragging and dropping into it,
        upload the image files with the filenames listed below.
        Images should be T1 MRI volumes in the NIfTI format.
      </div>
      <v-divider />
      <v-data-table
        :loading="loading"
        item-key="id"
        :items="pendingUploads"
        :headers="headers"
      />
    </v-card>
  </div>
</template>

<script>
import GirderUpload from '@girder/components/src/components/Upload.vue';
import { FileUploadHandler } from '@/upload';
import { getPaginated } from '@/utils';

export default {
  components: {
    GirderUpload,
  },
  inject: ['axios'],
  props: {
    datasetId: {
      type: String,
      required: true,
    },
    batchId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      pendingUploads: [],
      count: 0,
      // TODO should we show the metadata fields in the table?
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Patient identifier',
          value: 'patient',
        },
      ],
    };
  },
  async created() {
    this.FileUploadHandler = FileUploadHandler;
  },
  async mounted() {
    this.fetchPendingUploads();
  },
  methods: {
    async fetchPendingUploads() {
      this.loading = true;

      // Try/catch as the batch should no longer exist, but handle either case
      try {
        this.pendingUploads = await getPaginated(this.axios, `upload/batches/${this.batchId}/pending`);
        this.count = this.pendingUploads.length;
      } catch {
        this.count = 0;
      }

      // Navigate away if uploads are complete
      if (this.count === 0) {
        this.$router.push(`/dataset/${this.datasetId}`);
      }

      this.loading = false;
    },
    uploadDone() {
      this.fetchPendingUploads();
    },
  },
};
</script>
