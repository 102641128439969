<template>
  <div>
    <v-container>
      <v-sheet
        elevation="0"
        class="px-5 py-10"
      >
        <v-row
          v-for="(Feature, i) in Features"
          id="Features"
          :key="i"
          flat
          justify="center"
          align="center"
        >
          <v-col
            sm="6"
            md="6"
          >
            <v-img
              contain
              :src="Feature.image"
            />
          </v-col>
          <v-col>
            <h3 class="secondary--text headline font-weight-bold">
              {{ Feature.title }}
            </h3>
            <p class="secondary--text pt-2">
              {{ Feature.text }}
            </p>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
    <v-row
      justify="center"
      class="bg-color py-10"
    >
      <LaunchButtons />
    </v-row>
  </div>
</template>

<script>
import comparison from '../assets/Features/VBM-vs-UTM.png';
import LaunchButtons from './LaunchButtons.vue';

export default {
  name: 'LandingPage',

  components: {
    LaunchButtons,
  },

  data: () => ({
    Features: [
      {
        title: ' Localized Mass-Balancing',
        text: 'The localized mass-balancing links VBM with a global UTM and allows the discovery of effects at different spatial scales. The global UTM approach can miss local effects that are masked by global mass differences, local variations can be masked by the global difference in mass and thus not show up in the analys. The local mass-balancing provides an approach to enforce mass-balancing within local spatial regions. This local mass-balancing shows that the UTM approach is a continuum from VBM with mass balancing at each voxel to global mass-balancing in the original UTM approach.',
        image: comparison,
      },
    ],
  }),

};
</script>
