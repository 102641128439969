<template>
  <div>
    <section id="About">
      <v-row justify="center">
        <v-col
          sm="7"
          md="7"
        >
          <h1
            class="
              display-1
              font-weight-light
              condensed
              Cust_PrimaryTextColor--text
              text-center
              pb-15
            "
          >
            Analysis Pipeline
          </h1>
          <v-img
            :src="diagramPath"
            contain
          />
          <v-row class="my-5">
            <p class="secondary--text pt-2">
              Figure shows the proposed web-based image analysis pipeline from
              data upload to web-based result visualization. At each processing
              step (gray boxes) the user is requested to configure the step. The
              analysis then proceeds without requiring any further user input.
            </p>
          </v-row>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import diagram from '../assets/UTM-diagram.svg';
// In case there is need to update this image: the text font is Roboto and it's converted to a path

export default {
  data: () => ({
    diagramPath: diagram,
  }),
};
</script>
<style lang="css">
.bg-color {
  background: #eceff1;
}
</style>
