
















































































/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  ref, toRefs, onMounted, onBeforeUnmount, watch,
  defineComponent,
  computed,
  watchEffect,
} from '@vue/composition-api';

import '@kitware/vtk.js/Rendering/Profiles/Geometry';

// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Volume';
import type vtkProp3D from '@kitware/vtk.js/Rendering/Core/Prop3D';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';

// @ts-ignore
import { InterpolationType } from '@kitware/vtk.js/Rendering/Core/ImageProperty/Constants';

import {
  hexToRgb,
  setupSlice,
  RenderContext,
  SliceContext,
  useRenderContext,
  resetCamera,
  vtkImagesFromUrls,
} from '@/utils/vtk';

const DEFAULT_SLICE = 0;
const DEFAULT_OPACITY = 50;

// Segmented image colors
const CSF_INDEX = 1;
const CSF_COLOR = '#00FFB3';

const GMATTER_INDEX = 2;
const GMATTER_COLOR = '#5A00EB';

const WMATTER_INDEX = 3;
const WMATTER_COLOR = '#FAFAC0';

const COLOR_LEGEND = [
  { title: 'CSF', color: CSF_COLOR },
  { title: 'Grey Matter', color: GMATTER_COLOR },
  { title: 'White Matter', color: WMATTER_COLOR },
];

export default defineComponent({
  name: 'PreprocessedImageViewer',
  props: {
    segmentedImageUrl: {
      type: String,
      required: true,
    },
    registeredImageUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { segmentedImageUrl, registeredImageUrl } = toRefs(props);

    const loading = ref(true);
    const vtkContainer = ref<HTMLElement | null>(null);
    const renderContext = ref<RenderContext | null>(null);
    const topContext = ref<SliceContext | null>(null);
    const bottomContext = ref<SliceContext | null>(null);

    const slice = ref(DEFAULT_SLICE);
    const sliceDefault = ref(DEFAULT_SLICE);
    const sliceBounds = computed(() => {
      let min = 0;
      let max = 0;

      if (topContext.value && bottomContext.value) {
        [topContext.value, bottomContext.value].forEach((ctx) => {
          if (!ctx) {
            return;
          }

          min = Math.min(min, ctx.actor.getMinZBound());
          max = Math.max(max, ctx.actor.getMaxZBound());
        });
      }

      return {
        min,
        max,
      };
    });

    // Update slice to midpoint of slice bounds
    watch(sliceBounds, (val) => {
      const mid = ((val.max - val.min) / 2) + val.min;
      sliceDefault.value = mid;
      slice.value = mid;
    });

    // Opacity as a percent
    const opacity = ref(DEFAULT_OPACITY);
    watchEffect(() => {
      if (
        topContext.value === null
        || bottomContext.value === null
        || renderContext.value === null
      ) {
        return;
      }

      const { actor } = topContext.value;
      const { renderWindow } = renderContext.value;
      actor.getProperty().setOpacity(opacity.value / 100);

      renderWindow.render();
    });

    watch(slice, (val) => {
      if (!renderContext.value) {
        return;
      }

      // Update slices
      [topContext.value, bottomContext.value].forEach((ctx) => {
        if (!ctx) {
          return;
        }

        const { mapper } = ctx;
        mapper.setZSlice(val);
      });

      // Render
      const { renderWindow } = renderContext.value;
      renderWindow.render();
    });

    function resetOpacityAndSlice() {
      opacity.value = DEFAULT_OPACITY;
      slice.value = sliceDefault.value;
    }

    // Reset all settings
    function reset() {
      if (renderContext.value === null) {
        return;
      }

      resetCamera(renderContext.value.fullScreenRenderer);
      resetOpacityAndSlice();
    }

    async function init() {
      loading.value = true;

      renderContext.value = useRenderContext(vtkContainer.value, { reset });
      const { renderer } = renderContext.value;

      // Fetch images
      const [segmentedImage, registeredImage] = await vtkImagesFromUrls(
        segmentedImageUrl.value,
        registeredImageUrl.value,
      );

      /**
       * Top slice context
       */
      topContext.value = setupSlice(segmentedImage);
      renderer.addActor(topContext.value.actor as vtkProp3D as vtkActor);

      // Add coloring to top slice
      const rgb = vtkColorTransferFunction.newInstance();
      rgb.addRGBPointLong(0, 0, 0, 0, 0.5, 1.0);
      rgb.addRGBPointLong(CSF_INDEX, ...hexToRgb(CSF_COLOR), 0.5, 1.0);
      rgb.addRGBPointLong(GMATTER_INDEX, ...hexToRgb(GMATTER_COLOR), 0.5, 1.0);
      rgb.addRGBPointLong(WMATTER_INDEX, ...hexToRgb(WMATTER_COLOR), 0.5, 1.0);
      topContext.value.actor.getProperty().setInterpolationType(InterpolationType.NEAREST);
      topContext.value.actor.getProperty().setRGBTransferFunction(0, rgb);

      // Set zero values as transparent
      const opFun = vtkPiecewiseFunction.newInstance();
      opFun.addPoint(0, 0);
      opFun.addPoint(1, 1);
      topContext.value.actor.getProperty().setPiecewiseFunction(0, opFun);

      /**
       * Bottom slice context
       */
      bottomContext.value = setupSlice(registeredImage);
      renderer.addActor(bottomContext.value.actor as vtkProp3D as vtkActor);

      // Reset camera and Render
      reset();

      loading.value = false;
    }

    onMounted(() => {
      init();
    });

    function deleteSliceContexts() {
      if (topContext.value) {
        const { actor, mapper } = topContext.value;
        actor.delete();
        mapper.delete();
        topContext.value = null;
      }

      if (bottomContext.value) {
        const { actor, mapper } = bottomContext.value;
        actor.delete();
        mapper.delete();
        bottomContext.value = null;
      }
    }

    function deleteAllContexts() {
      deleteSliceContexts();

      if (renderContext.value) {
        const { fullScreenRenderer } = renderContext.value;
        fullScreenRenderer.delete();
        renderContext.value = null;
      }
    }

    onBeforeUnmount(() => {
      deleteAllContexts();
    });

    // Call update whenever the image url is changed
    watch([segmentedImageUrl, registeredImageUrl], () => {
      deleteSliceContexts();
      resetOpacityAndSlice();
      init();
    });

    return {
      loading,
      vtkContainer,
      opacity,
      slice,
      sliceBounds,
      reset,

      COLOR_LEGEND,
    };
  },
});
