/* eslint-disable @typescript-eslint/no-explicit-any */
import vtkCellPicker from '@kitware/vtk.js/Rendering/Core/CellPicker';
import { IJKLocation, XYZ } from './state';

export interface CrosshairLine {
  start: [number, number],
  end: [number, number],
}
export interface ColoredCrosshairLine extends CrosshairLine {
  color: string;
}
export type CrosshairLines = Record<XYZ, CrosshairLine>
type AxisRepr = [[number, number, number], [number, number, number]];

class CrosshairSet {
  imageRepresentation: any;
  imageData: any;
  imageView: any;
  renderer: any;
  renderWindow: any;
  imageCanvas: any;
  iSlice: number;
  jSlice: number;
  kSlice: number;

  constructor(
    imageRepresentation: any,
    imageView: any,
    imageCanvas: any,
    iSlice: any,
    jSlice: any,
    kSlice: any,
  ) {
    this.imageRepresentation = imageRepresentation;
    this.imageData = this.imageRepresentation.getInputDataSet();
    this.imageView = imageView;
    this.renderer = this.imageView.getRenderer();
    this.renderWindow = this.imageView.getOpenglRenderWindow();
    this.imageCanvas = imageCanvas;
    this.iSlice = iSlice;
    this.jSlice = jSlice;
    this.kSlice = kSlice;
  }

  getOrientation() {
    if (!this.imageRepresentation.getInputDataSet()) return undefined;
    return this.imageRepresentation.getInputDataSet().getDirection();
  }

  /** Return how to draw each line per axis. */
  getSliceLines(): CrosshairLines {
    if (!this.imageData) {
      throw new Error('Image Data is null/undefined!');
    }

    const [iMin, iMax, jMin, jMax, kMin, kMax] = this.imageData.getBounds();
    const iRepresentation: AxisRepr = [
      [iMin, this.jSlice, this.kSlice],
      [iMax, this.jSlice, this.kSlice],
    ];
    const jRepresentation: AxisRepr = [
      [this.iSlice, jMin, this.kSlice],
      [this.iSlice, jMax, this.kSlice],
    ];
    const kRepresentation: AxisRepr = [
      [this.iSlice, this.jSlice, kMin],
      [this.iSlice, this.jSlice, kMax],
    ];

    const [iPoints, jPoints, kPoints] = [iRepresentation, jRepresentation, kRepresentation].map(
      (representation) => representation.map(
        (point) => (
          this.renderWindow
            // Convert world coordinates to locations on canvas
            .worldToDisplay(point[0], point[1], point[2], this.renderer)
            // 3rd dimension not needed for 2d view
            .map((c: any) => c / devicePixelRatio).slice(0, 2)
        ),
      ).map(
        // Subtract value from height so it's drawn correctly and not flipped
        ([x, y]: [number, number]) => [x, this.imageCanvas.height - y] as [number, number],
      ),
    );
    return {
      x: {
        start: iPoints[0],
        end: iPoints[1],
      },
      y: {
        start: jPoints[0],
        end: jPoints[1],
      },
      z: {
        start: kPoints[0],
        end: kPoints[1],
      },
    };
  }

  // eslint-disable-next-line max-len
  getCrosshairsForAxis(axis: XYZ, colors: Record<XYZ, string>): [ColoredCrosshairLine, ColoredCrosshairLine] {
    const sliceLines = this.getSliceLines();
    switch (axis) {
      case 'x':
        return [
          Object.assign(sliceLines.z, { color: colors.y }),
          Object.assign(sliceLines.y, { color: colors.z }),
        ];
      case 'y':
        return [
          Object.assign(sliceLines.z, { color: colors.x }),
          Object.assign(sliceLines.x, { color: colors.z }),
        ];
      case 'z':
      default:
        return [
          Object.assign(sliceLines.y, { color: colors.x }),
          Object.assign(sliceLines.x, { color: colors.y }),
        ];
    }
  }

  getPicker() {
    const picker = vtkCellPicker.newInstance();
    picker.setPickFromList(1);
    picker.setTolerance(0);
    picker.initializePickList();
    picker.addPickList(this.imageRepresentation.getActors()[0]);
    return picker;
  }

  locationOfClick(clickEvent: any): IJKLocation | undefined {
    const picker = this.getPicker();
    picker.pick([clickEvent.position.x, clickEvent.position.y, 0], this.renderer);
    if (picker.getActors().length === 0) {
      return undefined;
    }

    const xyzLocation = picker.getPickedPositions()[0] as unknown as [number, number, number];
    return {
      i: xyzLocation[0],
      j: xyzLocation[1],
      k: xyzLocation[2],
    };
  }
}

export default CrosshairSet;
