/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */

import { Ref, ref } from '@vue/composition-api';

export type IJK = 'i' | 'j' | 'k';
export type XYZ = 'x' | 'y' | 'z';
export type IJKLocation = Record<IJK, number>;
export type XYZLocation = Record<XYZ, number>;

export function IJKToXYZ(ijk: IJK): XYZ {
  switch (ijk) {
    case 'i':
      return 'x';
    case 'j':
      return 'y';
    case 'k':
    default:
      return 'z';
  }
}

export function axisNumberToIJK(axis: number): IJK {
  switch (axis) {
    case 0:
      return 'i';
    case 1:
      return 'j';
    case 2:
    default:
      return 'k';
  }
}

export function useLocation(proxyManager: any) {
  const imageSource = proxyManager.getSources().find((s: any) => s.getName() === 'Image');
  if (!imageSource) {
    throw new Error('Could not find Image Source from proxy manager!');
  }
  const atlasSource = proxyManager.getSources().find((s: any) => s.getName() === 'Atlas');
  if (!atlasSource) {
    throw new Error('Could not find Atlas Source from proxy manager!');
  }

  const views: any[] = proxyManager.getViews();

  // Setup slice/view/representation for each axis
  const iView = views.find((v) => axisNumberToIJK(v.getAxis()) === 'i');
  const iRepresentation = proxyManager.getRepresentation(imageSource, iView);
  const iAtlasRepresentation = proxyManager.getRepresentation(atlasSource, iView);
  const iSlice = ref<number>(iRepresentation.getSlice());

  const jView = views.find((v) => axisNumberToIJK(v.getAxis()) === 'j');
  const jRepresentation = proxyManager.getRepresentation(imageSource, jView);
  const jAtlasRepresentation = proxyManager.getRepresentation(atlasSource, jView);
  const jSlice = ref<number>(jRepresentation.getSlice());

  const kView = views.find((v) => axisNumberToIJK(v.getAxis()) === 'k');
  const kRepresentation = proxyManager.getRepresentation(imageSource, kView);
  const kAtlasRepresentation = proxyManager.getRepresentation(atlasSource, kView);
  const kSlice = ref<number>(kRepresentation.getSlice());

  /** Return the corresponding i/j/k slice ref */
  function IJKToSliceRef(axis: IJK): Ref<number> {
    switch (axis) {
      case 'i':
        return iSlice;
      case 'j':
        return jSlice;
      case 'k':
      default:
        return kSlice;
    }
  }

  /** Return the corresponding view from this axis. */
  function IJKToView(axis: IJK): any {
    switch (axis) {
      case 'i':
        return iView;
      case 'j':
        return jView;
      case 'k':
      default:
        return kView;
    }
  }

  /** Return the corresponding representation from this axis. */
  function IJKToRepresentation(axis: IJK): [any, any] {
    switch (axis) {
      case 'i':
        return [iRepresentation, iAtlasRepresentation];
      case 'j':
        return [jRepresentation, jAtlasRepresentation];
      case 'k':
      default:
        return [kRepresentation, kAtlasRepresentation];
    }
  }

  function setSlice(axis: IJK, value: number) {
    // Set slice in each representation
    IJKToRepresentation(axis).forEach((rep) => {
      rep.setSlice(value);
    });

    // Render all views, so crosshairs are updated
    [iView, jView, kView].forEach((view) => {
      view.getRenderer().getRenderWindow().render();
    });
  }

  function setLocation(location: IJKLocation) {
    Object.keys(location).forEach((axis) => {
      const ijk = axis as IJK;
      setSlice(ijk, location[ijk]);
    });
  }

  // Set each slice ref to match representation when modified
  (['i', 'j', 'k'] as IJK[]).forEach((axis) => {
    IJKToRepresentation(axis)[0].onModified((rep: any) => {
      IJKToSliceRef(axis).value = rep.getSlice();
    });
  });

  return {
    // Slices
    iSlice,
    jSlice,
    kSlice,
    setSlice,
    setLocation,
    IJKToSliceRef,

    // Representations
    IJKToRepresentation,
    IJKToView,
  };
}
