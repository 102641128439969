





























































































/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  ref, toRefs, onMounted, onBeforeUnmount, watch,
  defineComponent,
  computed,
  watchEffect,
  inject,
} from '@vue/composition-api';

import '@kitware/vtk.js/Rendering/Profiles/Geometry';

// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Volume';
import type vtkProp3D from '@kitware/vtk.js/Rendering/Core/Prop3D';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';

// @ts-ignore
import { InterpolationType } from '@kitware/vtk.js/Rendering/Core/ImageProperty/Constants';

import { AxiosInstance } from 'axios';
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';

import type { Vector3 } from '@kitware/vtk.js/types';
import {
  setupSlice,
  RenderContext,
  SliceContext,
  useRenderContext,
  resetCamera,
  vtkImagesFromUrls,
  copyVtkImage,
} from '@/utils/vtk';

const DEFAULT_SLICE = 0;
const DEFAULT_SQUARES = 2;
const DEFAULT_OPACITY = 100;

/**
 * @param axis1: Index, extent and number of squares in axis 1
 * @param axis2: Index, extent and number of squares in axis 2
 * @returns true or false, indicating whether this voxel should be "on" or "off"
 */
function checkerboard(axis1: Vector3, axis2: Vector3) {
  const [i1, e1, n1] = axis1;
  const [i2, e2, n2] = axis2;
  return ((-1) ** (Math.trunc((i1 / e1) * n1) + Math.trunc((i2 / e2) * n2))) === 1;
}

function tileVtkImage(vtkImage: vtkImageData, n: number, against = true): vtkImageData {
  const newImage = copyVtkImage(vtkImage);

  // Apply checkerboard pattern
  const arr = newImage.getPointData().getScalars().getData();
  const [dimx, dimy] = newImage.getDimensions();
  arr.forEach((_, index) => {
    const x = index % dimx;
    const y = (index / dimx) % dimy;

    if (checkerboard([x, dimx, n], [y, dimy, n]) === against) {
      arr[index] = -Infinity;
    }
  });

  return newImage;
}

export default defineComponent({
  name: 'RegisteredImageViewer',
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    t1AtlasUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { imageUrl, t1AtlasUrl } = toRefs(props);
    const registeredImage = ref<vtkImageData>();
    const atlasImage = ref<vtkImageData>();

    const axios = inject<AxiosInstance>('axios');
    if (axios === undefined) {
      throw new Error('Must provide "axios" into component.');
    }

    const loading = ref(true);
    const vtkContainer = ref<HTMLElement | null>(null);
    const renderContext = ref<RenderContext | null>(null);
    const context = ref<SliceContext | null>(null);
    const atlasContext = ref<SliceContext | null>(null);

    const squares = ref(DEFAULT_SQUARES);
    const checkerboardBool = ref(false);
    const hideCheckerboard = ref(false);
    function tileSlice(s: SliceContext) {
      if (!registeredImage.value) {
        return;
      }

      // Set data
      s.mapper.setInputData(
        hideCheckerboard.value
          ? copyVtkImage(registeredImage.value)
          : tileVtkImage(registeredImage.value, squares.value, checkerboardBool.value),
      );

      // Add opacity filter
      const opFun = vtkPiecewiseFunction.newInstance();
      opFun.addPoint(-1, 0);
      opFun.addPoint(0, 1);
      s.actor.getProperty().setPiecewiseFunction(0, opFun);
    }

    // Re-tile if squares change
    watch([squares, checkerboardBool, hideCheckerboard], () => {
      if (!(context.value && renderContext.value)) {
        return;
      }

      tileSlice(context.value);
      renderContext.value.renderWindow.render();
    });

    const slice = ref(DEFAULT_SLICE);
    const sliceDefault = ref(DEFAULT_SLICE);
    const sliceBounds = computed(() => {
      let min = 0;
      let max = 0;

      if (context.value && atlasContext.value) {
        [context.value, atlasContext.value].forEach((ctx) => {
          if (!ctx) {
            return;
          }

          min = Math.min(min, ctx.actor.getMinZBound());
          max = Math.max(max, ctx.actor.getMaxZBound());
        });
      }

      return {
        min,
        max,
      };
    });

    function setSlices() {
      if (!renderContext.value) {
        return;
      }

      // Update slices
      [context.value, atlasContext.value].forEach((ctx) => {
        if (!ctx) {
          return;
        }

        const { mapper } = ctx;
        mapper.setZSlice(slice.value);
      });

      // Render
      const { renderWindow } = renderContext.value;
      renderWindow.render();
    }

    // Update slice to midpoint of slice bounds
    watch(sliceBounds, (val) => {
      const mid = ((val.max - val.min) / 2) + val.min;
      sliceDefault.value = mid;
      slice.value = mid;

      // Manually call setSlices in case slice hasn't actually changed in value
      setSlices();
    });

    // Also call setSlices any time slice changes in value
    watch(slice, setSlices);

    // Opacity as a percent
    const opacity = ref(DEFAULT_OPACITY);
    watchEffect(() => {
      if (
        context.value === null
        || atlasContext.value === null
        || renderContext.value === null
      ) {
        return;
      }

      const { actor } = context.value;
      const { renderWindow } = renderContext.value;
      actor.getProperty().setOpacity(opacity.value / 100);

      renderWindow.render();
    });

    function resetControls() {
      opacity.value = DEFAULT_OPACITY;
      slice.value = sliceDefault.value;
      squares.value = DEFAULT_SQUARES;
      checkerboardBool.value = false;
      hideCheckerboard.value = false;
    }

    // Reset all settings
    function reset() {
      if (renderContext.value === null) {
        return;
      }

      resetCamera(renderContext.value.fullScreenRenderer);
      resetControls();
    }

    async function init() {
      loading.value = true;

      // Fetch images
      [registeredImage.value, atlasImage.value] = await vtkImagesFromUrls(
        imageUrl.value,
        t1AtlasUrl.value,
      );

      // Setup slice contexts
      context.value = setupSlice(registeredImage.value);
      atlasContext.value = setupSlice(atlasImage.value);

      // Tile top slice
      tileSlice(context.value);

      // Set interpolation to prevent incorrect checkerboard pattern
      context.value.actor.getProperty().setInterpolationType(InterpolationType.NEAREST);
      atlasContext.value.actor.getProperty().setInterpolationType(InterpolationType.NEAREST);

      // Render context
      renderContext.value = useRenderContext(vtkContainer.value, { reset });
      const { renderer } = renderContext.value;
      renderer.addActor(atlasContext.value.actor as vtkProp3D as vtkActor);
      renderer.addActor(context.value.actor as vtkProp3D as vtkActor);

      // Reset camera and render
      reset();

      loading.value = false;
    }

    function deleteSliceContexts() {
      if (context.value) {
        const { actor, mapper } = context.value;
        actor.delete();
        mapper.delete();
        context.value = null;
      }

      if (atlasContext.value) {
        const { actor, mapper } = atlasContext.value;
        actor.delete();
        mapper.delete();
        atlasContext.value = null;
      }
    }

    function deleteAllContexts() {
      deleteSliceContexts();

      if (renderContext.value) {
        const { fullScreenRenderer } = renderContext.value;
        fullScreenRenderer.delete();
        renderContext.value = null;
      }
    }

    onBeforeUnmount(() => {
      deleteAllContexts();
    });

    // Call update whenever the image url is changed
    watch(imageUrl, async () => {
      deleteSliceContexts();
      resetControls();
      init();
    });

    // Call init when component is first mounted
    onMounted(async () => {
      init();
    });

    return {
      loading,
      vtkContainer,
      opacity,
      slice,
      squares,
      checkerboardBool,
      hideCheckerboard,
      sliceBounds,
      reset,
    };
  },
});
