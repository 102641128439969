






















import { defineComponent } from '@vue/composition-api';

import router from '@/router';
import GirderUpload from '@girder/components/src/components/Upload.vue';
import { UploadBatch } from '@/types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { BatchCSVUploadHandler } from '@/upload';

export default defineComponent({
  components: {
    GirderUpload,
  },
  props: {
    datasetId: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    function uploadDone([result]: UploadBatch[]) {
      router.push(`/dataset/${props.datasetId}/upload/${result.id}`);
    }

    return {
      uploadDone,
      BatchCSVUploadHandler,
    };
  },
});
