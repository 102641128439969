<template>
  <div>
    <section>
      <v-row
        justify="center"
        class="blue-grey lighten-5 secondary--text text-center py-5"
        height="200"
      >
        <v-col
          sm="8"
          md="8"
        >
          {{ footNotes }}
        </v-col>
      </v-row>
    </section>
    <section>
      <v-footer class="mb-n5 pb-10 white py-5">
        <v-row justify="center">
          <div class="Cust_LightTextColor--text">
            <strong>
              Copyright
              <v-icon
                class="Cust_LightTextColor--text"
                small
              >mdi-copyright</v-icon>
              {{ new Date().getFullYear() }}
              |
              <a
                class="Cust_LightTextColor--text"
                href="https://kitware.com"
                target="_blank"
              >Kitware, Inc.</a>
              All rights reserved |
              <a
                class="Cust_LightTextColor--text"
                href="https://www.kitware.com/what-we-offer/"
                target="_blank"
              >What We Do</a>
              |
              <a
                class="Cust_LightTextColor--text"
                href="https://kitware.com/privacy"
                target="_blank"
              >Privacy</a>
              |
              <v-dialog
                v-model="disclaimerOpen"
                width="600px"
              >
                <template #activator="{ on }">
                  <a
                    class="Cust_LightTextColor--text"
                    href="#"
                    target="_blank"
                    onclick="return false;"
                    v-on="on"
                  >
                    Disclaimer</a>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Disclaimer</span>
                  </v-card-title>
                  <v-card-text>
                    {{ disclaimerText }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="Cust_PrimaryTextColor"
                      text
                      @click="disclaimerOpen = false"
                    >Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              |
              <a
                class="Cust_LightTextColor--text"
                href="https://www.kitware.com/contact-us/#contact_form"
                target="_blank"
              >Contact</a>
            </strong>
          </div>
        </v-row>
      </v-footer>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',

  data: () => ({
    footNotes:
      'UTM is a joint collaborative work between Kitware, Washington University in St. Louis and University of North Carolina. Research reported in this publication was supported by the National Institute Of Mental Health of the National Institutes of Health under Award Number R42MH118845. The content is solely the responsibility of the authors and does not necessarily represent the official views of the National Institutes of Health.',
    disclaimerOpen: false,
    disclaimerText:
      'The content is solely the responsibility of the authors and does not necessarily represent the official views of the National Institutes of Health.',
  }),
};
</script>
