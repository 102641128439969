<template>
  <section id="CollaboratorsSection">
    <v-divider class="py-4" />
    <br>
    <p class="secondary--text text-center">
      We are proud to collaborate with researchers from prestigious
      organizations.
    </p>
    <v-row
      justify="center"
      class="py-10"
    >
      <v-col
        v-for="(Collaborator, i) in Collaborators"
        :key="i"
        sm="2"
        md="2"
      >
        <v-card
          flat
          color="rgb(0,0,0,0)"
        >
          <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
          <a
            :href="Collaborator.website"
            target="_blank"
          >
            <v-img
              :src="Collaborator.path"
              contain
            />
          </a>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import uncLogo from '../assets/CollaboratorsLogos/unc-logo.png';
import washuLogo from '../assets/CollaboratorsLogos/washu-logo.png';
import kitwareLogo from '../assets/CollaboratorsLogos/kitware-logo.png';

export default {
  data: () => ({
    Collaborators: [
      {
        path: uncLogo,
        website: 'https://www.unc.edu/',
      },
      {
        path: washuLogo,
        website: 'https://wustl.edu/',
      },
      {
        path: kitwareLogo,
        website: 'https://www.kitware.com/',
      },
    ],
  }),
};
</script>
