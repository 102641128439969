<template>
  <ul>
    <li
      v-for="batch in batches"
      :key="batch.id"
    >
      <router-link :to="`/dataset/${datasetId}/upload/${batch.id}`">
        Batch {{ batch.id }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import { getPaginated } from '@/utils';

export default {
  inject: ['axios'],
  props: {
    datasetId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    batches: [],
  }),
  async mounted() {
    this.loading = true;
    this.batches = await getPaginated(this.axios, `datasets/${this.datasetId}/upload_batches`);
    this.loading = false;
  },
};
</script>
