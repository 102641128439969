

















































































































import { User } from '@/types';
import {
  defineComponent, inject, onMounted, ref, toRefs, watch,
} from '@vue/composition-api';
import { AxiosInstance } from 'axios';

export default defineComponent({
  props: {
    datasetId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { datasetId } = toRefs(props);
    const axios = inject<AxiosInstance>('axios');
    if (axios === undefined) {
      throw new Error('Must provide "axios" into component.');
    }

    // Add user
    const addUserDialog = ref(false);
    const addUserErrors = ref('');
    const newUsername = ref('');
    watch(addUserDialog, (val) => {
      if (val === false) {
        newUsername.value = '';
      }
    });

    // Collaborator list
    const adminAccess = ref(false);
    const loading = ref(false);
    const collaborators = ref<User[]>([]);
    async function fetchCollaborators() {
      try {
        loading.value = true;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const resp = await axios!.get(`/datasets/${datasetId.value}/collaborators`);
        collaborators.value = resp.data;
      } finally {
        loading.value = false;
      }
    }

    async function fetchDataset() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const resp = await axios!.get(`/datasets/${datasetId.value}`);
      adminAccess.value = (resp.data?.access === 'owner');
    }

    onMounted(() => {
      fetchCollaborators();

      // TODO: Remove once app is restructured to not need this
      fetchDataset();
    });

    function formatUser(user: User): string {
      const { name, username } = user;
      if (name === '') {
        return username;
      }

      return `${name} (${username})`;
    }

    async function addCollaborator(username: string) {
      if (!newUsername.value) {
        throw new Error('Cannot add user with empty username');
      }

      const newCollaborators = [...collaborators.value, { username }];
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const resp = await axios!.put(`/datasets/${datasetId.value}/collaborators`, newCollaborators);
        collaborators.value = resp.data;
        addUserDialog.value = false;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const defaultMessage = 'Request failed. Check console/network log for more info.';
        const data = error.response?.data;
        if (data === undefined) {
          addUserErrors.value = defaultMessage;
        }

        // Assume array
        const err = data[0];
        if (err.username !== undefined) {
          addUserErrors.value = err.username;
        } else {
          addUserErrors.value = err;
        }
      }
    }

    async function removeCollaborator(index: number) {
      const newCollaborators = [...collaborators.value];
      newCollaborators.splice(index, 1);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const resp = await axios!.put(`/datasets/${datasetId.value}/collaborators`, newCollaborators);
      collaborators.value = resp.data;
    }

    return {
      adminAccess,
      addUserDialog,
      addUserErrors,
      newUsername,
      addCollaborator,

      loading,
      collaborators,
      formatUser,
      removeCollaborator,
    };
  },
});
