import '@mdi/font/css/materialdesignicons.min.css';
import axios from 'axios';
import Girder from '@girder/components/src';
import OauthClient from '@girder/oauth-client';
import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import VueCompositionAPI, { ref } from '@vue/composition-api';
import { User } from '@/types';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';

Vue.use(VueCompositionAPI);
Vue.use(Girder);

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
});

const oauthClient = new OauthClient(
  process.env.VUE_APP_OAUTH_API_ROOT,
  process.env.VUE_APP_OAUTH_CLIENT_ID,
);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
});

oauthClient.maybeRestoreLogin().then(async () => {
  Object.assign(axiosInstance.defaults.headers.common, oauthClient.authHeaders);

  const resp = await axiosInstance.get<User | ''>('/users/me');
  const user = ref(resp.status === 204 ? null : resp.data);

  new Vue({
    provide: {
      axios: axiosInstance,
      girderRest: axiosInstance, // needed for using GWC uploader
      oauthClient,
      user,
    },
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
});
