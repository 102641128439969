import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

/** **********
 * Components
 *********** */
import Datasets from '@/views/Datasets.vue';
import Dataset from '@/views/dataset/Dataset.vue';
import UploadBatch from '@/views/dataset/components/UploadBatch.vue';
import Analysis from '@/views/analysis/Analysis.vue';

// Other
import Home from '@/views/landingpage/Home.vue';
import Guide from '@/views/userguide/Guide.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/guide',
    component: Guide,
  },
  {
    path: '/datasets',
    component: Datasets,
  },
  {
    path: '/dataset/:datasetId',
    component: Dataset,
    props: true,
  },
  {
    path: '/dataset/:datasetId/upload/:batchId',
    component: UploadBatch,
    props: true,
  },
  {
    path: '/analysis/:analysisId',
    component: Analysis,
    props: true,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    return undefined;
  },
});

export default router;
