<template>
  <div>
    <v-row>
      <v-col>
        <v-container>
          <v-data-table
            :headers="tableHeaders"
            :items="tableData"
            :expanded.sync="expanded"
            :search="search"
            :loading="loading"
            class="elevation-1"
          >
            <template #top>
              <v-toolbar flat>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                />
              </v-toolbar>
            </template>
            <tr>
              <td />
            </tr>
            <template #expanded-item="{ headers }">
              <tr
                v-for="(item, index) in tableHeaders"
                :key="index"
                :colspan="headers.length"
              />
            </template>
          </v-data-table>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getPaginated } from '@/utils';

export default {
  inject: ['axios'],
  props: {
    datasetId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      expanded: [],
      images: [],
      loading: true,
      search: '',
    };
  },
  computed: {
    tableHeaders() {
      const fixedHeaders = [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Patient ID',
          value: 'patient',
        },
      ];
      if (this.images.length) {
        return [...fixedHeaders, ...Object.keys(this.images[0].metadata).map((k) => ({
          text: k,
          value: k,
        }))];
      }
      return fixedHeaders;
    },
    tableData() {
      return this.images.map((image) => ({
        name: image.name,
        patient: image.patient,
        ...image.metadata,
      }));
    },
  },
  async mounted() {
    this.loading = true;
    this.images = await getPaginated(this.axios, `datasets/${this.datasetId}/images`);
    this.loading = false;
  },
};
</script>
