<template>
  <div>
    <Banner />

    <div id="guide-text">
      <section id="intro">
        <h1>Introduction <span class="anchorthorpe"><a href="#intro">#</a></span> </h1>
        <p>
          Unbalanced transport morphometry (UTM)
          is an image population analysis technique based on optimal transport theory.
          Given a neuroimaging dataset, UTM
          detects local and global changes in amounts of tissue, and it can
          separate between changes in the amount and changes in the location of matter.
          This allows UTM to discover effects not visible in traditional voxel-based analysis.
        </p>
        <p>
          This web application provides UTM analysis for T1-weighted MRI datasets with associated
          tabular data.
        </p>
        <p>
          UTM can be viewed as a type of Tensor Based Morphometry (TBM), because it analyzes the
          Jacobian determinant images derived from non-parameteric registation of subject images to
          a template. Instead of directly correlating Jacobian determinant values to clinical
          variables of interest, UTM includes an additional feature extraction step that
          produces Optimal Transport Features (OTF),
          and these OTF are then correlated to clinical variables.
          The pipeline that we apply in this web application can be summarized as follows:
        </p>
        <p>
          <ol>
            <li> Perform bias field correction. </li>
            <li> Register each image to an atlas. </li>
            <li> Segment each aligned image to obtain a gray matter mask. </li>
            <li>
              Take the Jacobian determinant of the deformation that aligned each image and restrict
              it to the gray matter mask.<br>
              This results in <em>tissue density images</em>, which are the main input into the
              optimal transport analysis pipeline.
            </li>
            <li> Construct a template out of the tissue density images. </li>
            <li>
              Solve the unbalanced optimal transport problem from the template to each
              tissue density image.
            </li>
            <li>
              Compute the OTF, yielding a mass allocation image and
              a transport cost image for each subject.
            </li>
            <li>
              Compute the voxel-wise correlation of the OTF with a target variable of interest,
              creating correlation images.
            </li>
          </ol>
        </p>
        <p>
          More details can be found in
          <a
            target="_blank"
            :href="paperLink"
          >
            <v-icon color="#008BE4">mdi-file-document-outline</v-icon>our paper</a>
          (or <a
            target="_blank"
            :href="preprintLink"
          >preprint</a>).
        </p>
      </section>

      <section id="before-start">
        <h1>Before you start <span class="anchorthorpe"><a href="#before-start">#</a></span></h1>
        <p> Here is what you will need to run the UTM analysis:</p>
        <p>
          <ul>
            <li>
              A collection of <strong>T1-weighted MRI</strong> in the
              <a
                href="https://nifti.nimh.nih.gov/"
                target="_blank"
              > NIfTI file format</a>.
              Images should <strong>not be skull-stripped</strong>,
              since they will be registered to the SRI24
              atlas, which includes skull.
              Images should contain correct orientation information,
              at least roughly indicating the meaning of each axis in anatomical coordinates.
              This is because the image registration step will not permute axes or perform
              large rotations very well.
            </li>
            <li>
              A table of features, saved as a comma-separated values (CSV) file.
              The first row should be a header row, and all other rows should be data rows.
              There should be one column called <span class="code">name</span>
              and one or more additional columns for feature variables.
              Besides the header row, there should be one row per image file,
              with the entry under <span class="code">name</span>
              being an exact filename.
              All entries in the feature variable columns must <strong>be numeric values</strong>.
              Example:
              <table id="example-csv-table">
                <tr>
                  <th>name</th>
                  <th>age</th>
                  <th>clinical_dementia_rating</th>
                </tr>
                <tr>
                  <td>OAS1_0003_MR1.nii</td>
                  <td>73</td>
                  <td>0.5</td>
                </tr>
                <tr>
                  <td>OAS1_0032_MR1.nii</td>
                  <td>89</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>OAS1_0035_MR1.nii</td>
                  <td>84</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td style="text-align:center">
                    ...
                  </td>
                  <td style="text-align:center">
                    ...
                  </td>
                  <td style="text-align:center">
                    ...
                  </td>
                </tr>
              </table>
              The analysis is ultimately a voxel-wise
              correlation against the feature columns in the table.
              Therefore, when an effect is present, a large population of images
              is desirable in order to obtain statistically significant results.
            </li>
          </ul>
        </p>
      </section>

      <section id="create-dataset">
        <h1>Create a dataset<span class="anchorthorpe"><a href="#create-dataset">#</a></span></h1>
        <p>
          A <em>dataset</em> is a data table and a collection of images that
          will be put through the feature extraction and population analysis pipelines.
          Once you launch the web app, create a dataset using the
          <v-btn
            color="secondary"
            class="m-2"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            Create New Dataset
          </v-btn>
          button.
          Here you can set a name and description for your dataset,
          and choose whether you want it to be <em>public</em>.
          Making your dataset public allows anyone to see the results,
          but not to run processing.
          Adding other users as collaborators to your dataset allows
          them to see results and to run processing.
        </p>
        <p>
          The page for your newly created dataset should now be loaded,
          offering some of the tabs shown below.
          Click through the tabs below to see a summary description of each one.
        </p>
        <p class="center">
          <v-tabs>
            <v-tab>
              Upload Table
            </v-tab>
            <v-tab-item class="tab-help-text">
              Use this tab to upload your CSV file.
            </v-tab-item>
            <v-tab>
              Upload Images
            </v-tab>
            <v-tab-item class="tab-help-text">
              Use this tab to upload your images.
              Each time you upload a CSV file to add rows of data,
              a <em>batch</em> of rows is created here to await image upload.
              Click on the batch for which
              you are ready to upload images,
              and then use the upload page that comes up to upload each
              listed image file.
            </v-tab-item>
            <v-tab>
              Images
            </v-tab>
            <v-tab-item class="tab-help-text">
              Here you can see a list of images that were successfully uploaded,
              alongside their associated data from the table.
            </v-tab-item>
            <v-tab>
              Preprocessing
            </v-tab>
            <v-tab-item class="tab-help-text">
              Use this tab to run preprocessing.
              Preprocessing includes bias field correction,
              registration to an atlas,
              segmentation of tissue masks,
              and creation of tissue density feature images.
              Visualization tools are provided to inspect the
              output of this step.
            </v-tab-item>
            <v-tab>
              Analysis
            </v-tab>
            <v-tab-item class="tab-help-text">
              Use this tab to run the UTM analysis pipeline,
              generating voxel-wise correlation images.
              Once an analysis run is complete, a button is provided to
              visualize the results.
            </v-tab-item>
            <v-tab>
              Access
            </v-tab>
            <v-tab-item class="tab-help-text">
              Use this tab to control sharing your dataset with collaborators.
            </v-tab-item>
          </v-tabs>
        </p>
      </section>

      <section id="upload-data">
        <h1>Upload data<span class="anchorthorpe"><a href="#upload-data">#</a></span></h1>
        <p>
          <ol>
            <li>
              Upload your CSV table in the <span class="tab-name">Upload Table</span> tab.
              This will direct you to a newly created <em>batch</em> of images awaiting upload,
              which can also be accessed via the <span class="tab-name">Upload Images</span> tab.
            </li> <li>
              For each image filename listed in the batch, upload the corresponding NIfTI file
              with that name. Names must exactly match the displayed filename, which is taken
              from the <span class="code">name</span> column of your CSV table.
              Images can be uploaded one-by-one, a few at a time, or in one shot.
              Refresh the batch page at any time to update the list of images awaiting upload.
            </li>
            <li>
              Once all images have been uploaded, return to your dataset page by clicking on the
              UTM icon in the top left, or the
              <v-btn>
                <v-icon left>
                  mdi-arrow-left
                </v-icon>Back to dataset
              </v-btn> button.
              You can now check that the images are all
              listed in the <span class="tab-name">Images</span> tab.
            </li>
          </ol>
        </p>
      </section>

      <section id="run-preprocess">
        <h1>Run preprocessing<span class="anchorthorpe"><a href="#run-preprocess">#</a></span></h1>
        <p>
          Preprocessing includes bias field correction,
          non-parametric registration of images to an atlas,
          segmentation into tissue classes,
          and the creation of tissue density of images.
        </p>

        <p>
          Once your table and images are uploaded, you are ready to run preprocessing in the
          <span class="tab-name">Preprocessing</span> tab. Choose a preprocessing engine from
          the dropdown menu (currently only ANTs is available) and click
          <v-btn>Run</v-btn> to begin.
        </p>

        <p>
          Preprocessing can take hours for a few tens of images or days for a hundreds of images.
          To check on the progress of preprocessing, refresh the page to see how many images
          have their <v-btn
            color="blue accent-3"
            dark
          >
            Visualize
          </v-btn> button enabled;
          this button becomes enabled for each image that has completed some of its preprocessing
          steps.
        </p>
      </section>

      <section id="vis-preprocess">
        <h1>
          Visualize preprocessing<span class="anchorthorpe"><a href="#vis-preprocess">#</a></span>
        </h1>
        <p>
          In the <span class="tab-name">Preprocessing</span> tab, images that have completed some
          preprocessing steps will have their <v-btn
            color="blue accent-3"
            dark
          >
            Visualize
          </v-btn> button enabled.
          This button provides the option to
          inspect the output of each completed preprocessing step:
          <em>Registration</em>, <em>Jacobian</em>, <em>Segmentation</em>, and
          <em>Feature Image</em>.
          Each viewer displays a 3D image by showing 2D slices,
          with a <em>Slice</em> slider to scroll through the slices.
        </p>

        <section
          id="vis-reg"
          class="subsection"
        >
          <h2>Registration<span class="anchorthorpe"><a href="#vis-reg">#</a></span></h2>
          <p>
            This preprocessing visualization
            shows a checkerboard display of the registered subject image against
            the target atlas image, for the purpose of inspecting the quality of image alignment.
            <v-img
              :src="regExampleImage"
              class="center figure"
              :max-height="preprocessViewerFigHeight"
              contain
              eager
            />
          </p>
        </section>

        <section
          id="vis-jac"
          class="subsection"
        >
          <h2>Jacobian<span class="anchorthorpe"><a href="#vis-jac">#</a></span></h2>
          <p>
            This preprocessing visualization
            shows the Jacobian determinant of the mapping that registered the given subject image
            to the atlas. The target atlas is shown in the background,
            and the Jacobian determinant values
            are overlayed according to a logarithmic color scale. A Jacobian <nobr>of 1</nobr>,
            which indicates no local volume changes
            during alignment, is always in the middle of the color scale, displaying as transparent.
          </p>
          <p>
            When restricted to one tissue type, such as gray matter,
            the Jacobian determinant becomes the tissue density image
            that goes into the UTM analysis.
            Thus this Jacobian viewer provides a whole-brain view of the features
            that will go into the analysis.
            It also serves as an additional inspection of image alignment quality:
            if registration results in <em>folding</em>&mdash;
            i.e. negative Jacobian determinant values&mdash; then fold locations are displayed
            in bright red.
          </p>
          <p>
            In the example shown below, a Jacobian <nobr>of 2</nobr> is fully green and a
            Jacobian <nobr>of 1/2</nobr> is fully blue,
            <nobr>with 1</nobr> in the middle being fully transparent and
            with varying transparency in between.
            Dragging the <em>Window</em> slider controls the color mapping by setting the width
            of the color scale in terms of the number of standard deviations of the
            log-Jacobian values of the given subject image.
            <v-img
              :src="jacExampleImage"
              class="center figure"
              :max-height="preprocessViewerFigHeight"
              contain
              eager
            />
          </p>
        </section>

        <section
          id="vis-seg"
          class="subsection"
        >
          <h2>Segmentation<span class="anchorthorpe"><a href="#vis-seg">#</a></span></h2>
          <p>
            This preprocessing visualization
            shows the image segmentation into three brain tissue classes:
            CSF, white matter, and grey matter.
            <v-img
              :src="segExampleImage"
              class="center figure"
              :max-height="preprocessViewerFigHeight"
              contain
              eager
            />
          </p>
        </section>

        <section
          id="vis-feat"
          class="subsection"
        >
          <h2>Feature Image<span class="anchorthorpe"><a href="#vis-feat">#</a></span></h2>
          <p>
            This preprocessing visualization
            shows the tissue density image that goes into the UTM analysis.
            For each subject, this feature image is the ultimate output that we want
            from the preprocessing pipeline,
            and the collection of feature images over all subjects is the ultimate input
            into the analysis pipeline.
            The feature image is produced by restricting the Jacobian determinant image
            to the grey matter tissue
            region, resulting in an atlas-space representation of grey matter tissue density.
            <v-img
              :src="featExampleImage"
              class="center figure"
              :max-height="preprocessViewerFigHeight"
              contain
              eager
            />
          </p>
        </section>
      </section>

      <section id="analysis">
        <h1>Run analysis<span class="anchorthorpe"><a href="#analysis">#</a></span></h1>
        <p>
          If preprocessing is complete and all the feature (tissue density) images are generated,
          then you can use the <span class="tab-name">Analysis</span> tab to run UTM analysis.
          Click <v-btn>Run</v-btn> and wait; again this process can take some time,
          comparable to the time spent on preprocessing.
        </p>
      </section>

      <section id="results">
        <h1>Visualize results<span class="anchorthorpe"><a href="#results">#</a></span></h1>
        <p>
          There are two ways to visualize analysis results: (1) using the vtk.js
          web viewer or (2) downloading the results and using the included R app.
          We include instructions for both approaches below, but
          the first approach is recommended.
        </p>

        <section
          id="results-vtk"
          class="subsection"
        >
          <h2>
            Visualize results online with vtk.js
            <span class="anchorthorpe"><a href="#results-vtk">#</a></span>
          </h2>
          <p>
            Once analysis is complete, a button
            <v-btn
              large
              color="primary"
              class="mr-2"
            >
              View Analysis
            </v-btn>
            will become avaialble in the <span class="tab-name">Analysis</span> tab.
            This button launches a vtk.js tool for visualizing correlation maps.
          </p>
          <p>
            Here is an example screenshot with annotations that
            we will use to describe the controls:
            <v-img
              :src="vtkjsAppExampleImage"
              class="center figure"
              eager
            />
            Choose a <nobr>variable <span class="circled-digit">1</span></nobr>
            and an optimal transport <nobr>feature <span class="circled-digit">2</span></nobr>
            for which you'd like to display the correlation image.
            The <nobr>variables <span class="circled-digit">1</span></nobr>
            are simply the feature columns from the CSV table that you uploaded
            (i.e. any columns other than the <span class="code">name</span> column).
            The features <em>allocation</em> and <em>transport</em> <nobr>
              in
              <span class="circled-digit">2</span>
            </nobr> are the
            optimal transport features (OTF)
            that were extracted as part of the UTM analysis pipline.
            These are voxel-wise features that form images on the grey matter tissue region.
            Specifically, <em>allocation</em> refers to the <em>mass allocation image</em>
            and <em>transport</em> refers to the <em>transport cost image</em>.
            Refer to <a
              target="_blank"
              :href="paperLink"
            >
              <v-icon color="#008BE4">mdi-file-document-outline</v-icon>our paper
            </a>
            (or <a
              target="_blank"
              :href="preprintLink"
            >preprint</a>)
            to understand the meaning of these OTF.
          </p>
          <p>
            Click inside the slice <nobr>views <span class="circled-digit">3</span></nobr>
            to focus the the crosshair on different points in the 3D space.
            The correlation and the <nobr>p-value <span class="circled-digit">4</span></nobr>
            will update to reflect the values at the focused voxel.
            The red/blue colors overlayed on the atlas image in
            the slice <nobr>views <span class="circled-digit">3</span></nobr>
            represent correlation values, but voxels are only colored in if their
            p-value is less than the threshold chosen at the
            <nobr>slider <span class="circled-digit">5</span></nobr>.
            The color bar <span class="circled-digit">6</span>
            indicates how colors are used to represent correlation values.
          </p>
          <p>
            Beware the
            <a
              target="_blank"
              href="https://en.wikipedia.org/wiki/Multiple_comparisons_problem"
            >
              multiple comparison problem</a>.
            A statistical test is carried out at each voxel,
            so, for example, setting <nobr>the slider <span class="circled-digit">5</span></nobr>
            to <span class="code">0.05</span> incurs a much higher than 5% chance
            of making a Type I error and discovering a non-existent effect.
            One quick and dirty, though overly conservative, way to address this is
            to (over)estimate the number of independent statistical tests and set
            <nobr>the slider <span class="circled-digit">5</span></nobr>
            to a Bonferroni corrected p-value;
            see an example discussed
            <a
              target="_blank"
              href="https://github.com/KitwareMedical/UTM/tree/main/Example/Annulus"
            >
              here</a>.
          </p>
        </section>

        <section
          id="results-shiny"
          class="subsection"
        >
          <h2>
            Visualize downloaded results with Shiny app
            <span class="anchorthorpe"><a href="#results-shiny">#</a></span>
          </h2>
          <p>
            This section describes a legacy visualizer and it can be skipped if you are not
            interested in using downloaded result files. This approach
            visualizes analysis results by downloading them and running the included R app
            which uses Shiny to deliver the visualization locally in your browser.
          </p>
          <p>
            Once analysis is complete, a button
            <v-btn
              large
              color="secondary"
            >
              Download
              <v-icon right>
                mdi-open-in-new
              </v-icon>
            </v-btn>
            will become avaialble in the <span class="tab-name">Analysis</span> tab.
            This allows you to save a zip file containing the analysis results, which
            you can visualize if you have R installed on your system.
            Run the following R code to install the necessary dependencies:
          </p>
          <div class="code">
            packages = c("corrplot", "DT", "htmlwidgets", "optparse", "raster", "rmarkdown",
            "shiny", "shinyBS", "shinythemes", "shinyWidgets", "stringr", "tidyverse")<br>
            install.packages(pkgs = packages)<br>
            install_github("samuelgerber/vtkwidgets")
          </div>
          <p>
            To run the visualization, extract the zip file and run the
            <span class="code">app.R</span> script within:
          </p>
          <div class="code">
            Rscript app.R
          </div>
          <p>
            Here is an example screenshot:
            <v-img
              :src="shinyAppExampleImage"
              class="center figure"
              eager
            />
            The elements annotated in the screenshot have the same functionality as the
            corresponding elements of the vtk.js visualizer described above. The only difference
            is that the color mapping <span class="circled-digit">6</span>
            that represents correlation values can be adjusted using
            <nobr>the slider <span class="circled-digit">7</span></nobr>.
          </p>
        </section>
      </section>

      <section id="access">
        <h1>Manage access<span class="anchorthorpe"><a href="#access">#</a></span></h1>
        <p>
          Add other users as collaborators using the <span class="tab-name">Access</span> tab.
          Click
          <v-btn
            color="success"
            class="pl-0"
          >
            <v-icon>mdi-plus</v-icon>
            Add New Collaborator
          </v-btn>
          and provide the username of the account for which you'd like to give privileged access
          to the dataset.
        </p>
        <p>
          When you initially create your dataset, you are given
          an option to make it <em>public</em>.
          Making a public dataset allows anyone to see the results,
          while still only allowing collaborators to run processing.
          If you made your dataset non-public, then only collaborators
          can view the data, run processing, or view results.
        </p>
      </section>
    </div> <!-- guide-text -->

    <div style="margin-top:6em" />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/landingpage/components/Footer.vue';
import Banner from './components/Banner.vue';
import regExampleImage from './assets/reg-example.png';
import segExampleImage from './assets/seg-example.png';
import jacExampleImage from './assets/jac-example.png';
import featExampleImage from './assets/feat-example.png';
import shinyAppExampleImage from './assets/shiny-app-example.svg';
import vtkjsAppExampleImage from './assets/vtkjs-app-example.svg';

export default {
  components: {
    Banner,
    Footer,
  },
  data: () => ({
    regExampleImage,
    segExampleImage,
    jacExampleImage,
    featExampleImage,
    shinyAppExampleImage,
    vtkjsAppExampleImage,
    preprocessViewerFigHeight: '500px',
    preprintLink: 'http://arxiv.org/abs/2208.05891',
    paperLink: 'https://doi.org/10.1016/j.media.2022.102696',
  }),
};
</script>

<style>

#guide-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;

  counter-reset:section;
}

#guide-text h1 {
  margin: 2em;
  margin-left: 0em;
  margin-bottom: 0em;
  font-family: 'Roboto';
  font-size: 16pt;
  font-weight: bold;
  color:rgb(73, 73, 73);

  counter-reset:subsection;
}

#guide-text h2 {
  margin: 1.5em;
  margin-left: 1em;
  margin-bottom: 0em;
  font-family: 'Roboto';
  font-size: 16pt;
  font-weight:500;
  color:rgb(73, 73, 73);
}

#guide-text h1:before
{
  counter-increment:section;
  content:counter(section) ". ";
}
#guide-text h2:before
{
  counter-increment:subsection;
  content:counter(section) "." counter(subsection) ". ";
}

p {
  margin: 1.5em;
  margin-bottom: 0em;
  font-family: 'Roboto';
  font-size: 14pt;
}

section p {margin-left:1em;}
.subsection p {margin-left:2em;}

.anchorthorpe {
  display: none;
  padding-left: 0.5em;
}

h1:hover .anchorthorpe,
h2:hover .anchorthorpe {
  display: inline;
}

.anchorthorpe a {
  text-decoration: none;
}

.center {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.code {
  font-family: monospace;
  color: rgb(100, 0, 100);
  background: rgba(0, 0, 0, 0.1);
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 90%;
}

div.code {
  overflow-x: auto;
  white-space: nowrap;
  margin: 1em;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

#example-csv-table {
  font-family: monospace;
  font-size: 80%;
  border-collapse: collapse;
  margin: 1em;
}

#example-csv-table td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
  padding-right: 2em;
}

#example-csv-table tr:nth-child(even){background-color: #f2f2f2;}

#example-csv-table tr:hover {background-color: #ddd;}

#example-csv-table th {
  padding: 8px;
  padding-right: 2em;
  text-align: left;
  background-color: #858585;
  color: white;
}

.tab-help-text {
  margin:auto;
  max-width: 600px;
}

.tab-name {
  text-transform: uppercase;
  /* color: #777777; */
  color: #008BE4;
  font-weight: bold;
  font-size: 80%;
  padding-left: 2px;
  padding-right: 2px;
}

.figure {
  margin-top:1em;
  margin-bottom:1em;
}

.circled-digit {
  display: inline-block;
  width: 25px;
  line-height: 15px;
  border-radius: 50%;
  padding: 2px;
  background: #fff;
  border: 3px solid #3dac41ff;
  color: #3dac41ff;;
  text-align: center;
  font-weight: bold;
}

#guide-text a {
  text-decoration: none;
  color: #008BE4;
}

</style>
