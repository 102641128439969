





















import {
  computed, defineComponent, inject, Ref,
} from '@vue/composition-api';
import OAuthClient from '@girder/oauth-client';
import { User } from '@/types';
import router from '@/router';
import HomeLogo from './HomeLogo.vue';

export default defineComponent({
  components: { HomeLogo },
  setup() {
    const disableTitleBar = ['/', '/guide']; // routes for which TitleBar should be disabled
    const disabled = computed(() => disableTitleBar.includes(router.currentRoute.path));
    const oauthClient = inject<OAuthClient>('oauthClient');
    if (oauthClient === undefined) {
      throw new Error('Must provide "oauthClient" into component.');
    }

    const user = inject<Ref<User | null>>('user');
    if (user === undefined) {
      throw new Error('Must provide "user" into component.');
    }

    const loginText = computed(() => user.value?.username || 'Login');
    const logInOrOut = () => {
      if (oauthClient.isLoggedIn) {
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure you want to log out?')) {
          oauthClient.logout();
          user.value = null;
        }
      } else {
        oauthClient.redirectToLogin();
      }
    };

    return {
      user,
      loginText,
      logInOrOut,
      disabled,
    };
  },
});
