















































































/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  ref, toRefs, onMounted, onBeforeUnmount, watch,
  defineComponent,
  computed,
  watchEffect,
} from '@vue/composition-api';

import '@kitware/vtk.js/Rendering/Profiles/Geometry';

// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Volume';
import type vtkProp3D from '@kitware/vtk.js/Rendering/Core/Prop3D';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';

// @ts-ignore
import { InterpolationType } from '@kitware/vtk.js/Rendering/Core/ImageProperty/Constants';

import {
  useRenderContext,
  setupSlice,
  RenderContext,
  SliceContext,
  resetCamera,
  vtkImageDataFromUrl,
} from '@/utils/vtk';

const DEFAULT_SLICE = 0;
const ERROR_THRESHOLD = -0.01;
const BASE_THRESHOLD = 0;

export default defineComponent({
  name: 'FeatureImageViewer',
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { imageUrl } = toRefs(props);

    const loading = ref(true);
    const vtkContainer = ref<HTMLElement | null>(null);
    const renderContext = ref<RenderContext | null>(null);
    const context = ref<SliceContext | null>(null);

    const imageData = computed(() => context.value?.imageData);
    const colorLegendStyle = computed(() => {
      if (!imageData.value) {
        return undefined;
      }

      // Add red color stops if necessary
      const { minimum } = imageData.value;
      const stops = minimum < BASE_THRESHOLD
        ? 'red 5%, black 5%'
        : 'black 0%';

      return {
        'background-image': `linear-gradient(90deg, ${stops}, white 100%)`,
      };
    });

    const slice = ref(DEFAULT_SLICE);
    const sliceDefault = ref(DEFAULT_SLICE);
    const sliceBounds = computed(() => {
      let min = 0;
      let max = 0;

      if (context.value) {
        min = Math.min(min, context.value.actor.getMinZBound());
        max = Math.max(max, context.value.actor.getMaxZBound());
      }

      return {
        min,
        max,
      };
    });

    // Update slice to midpoint of slice bounds
    watch(sliceBounds, (val) => {
      const mid = ((val.max - val.min) / 2) + val.min;
      sliceDefault.value = mid;
      slice.value = mid;
    });

    // Opacity as a percent
    watchEffect(() => {
      if (context.value === null || renderContext.value === null) {
        return;
      }

      const { renderWindow } = renderContext.value;
      renderWindow.render();
    });

    watch(slice, (val) => {
      if (!context.value || !renderContext.value) {
        return;
      }

      // Update slices
      const { mapper } = context.value;
      mapper.setZSlice(val);

      // Render
      const { renderWindow } = renderContext.value;
      renderWindow.render();
    });

    function resetSlice() {
      slice.value = sliceDefault.value;
    }

    // Reset all settings
    function reset() {
      if (renderContext.value === null) {
        return;
      }

      resetCamera(renderContext.value.fullScreenRenderer);
      resetSlice();
    }

    async function init() {
      loading.value = true;

      renderContext.value = useRenderContext(vtkContainer.value, { reset });
      const { renderer } = renderContext.value;

      // Setup slice context
      context.value = setupSlice(await vtkImageDataFromUrl(imageUrl.value));
      renderer.addActor(context.value.actor as vtkProp3D as vtkActor);

      // Add coloring to slice
      const { imageData: { maximum } } = context.value;
      const rgb = vtkColorTransferFunction.newInstance();
      rgb.addRGBPointLong(ERROR_THRESHOLD, 1, 0, 0, 0.0, 1.0);
      rgb.addRGBPoint(BASE_THRESHOLD, 0, 0, 0);
      rgb.addRGBPoint(maximum, 1, 1, 1);
      context.value.actor.getProperty().setUseLookupTableScalarRange(true);
      context.value.actor.getProperty().setInterpolationType(InterpolationType.NEAREST);
      context.value.actor.getProperty().setRGBTransferFunction(0, rgb);

      // Reset camera and Render
      reset();

      loading.value = false;
    }

    onMounted(() => {
      init();
    });

    function deleteSliceContexts() {
      if (context.value) {
        const { actor, mapper } = context.value;
        actor.delete();
        mapper.delete();
        context.value = null;
      }
    }

    function deleteAllContexts() {
      deleteSliceContexts();

      if (renderContext.value) {
        const { fullScreenRenderer } = renderContext.value;
        fullScreenRenderer.delete();
        renderContext.value = null;
      }
    }

    onBeforeUnmount(() => {
      deleteAllContexts();
    });

    // Call update whenever the image url is changed
    watch(imageUrl, () => {
      deleteSliceContexts();
      resetSlice();
      init();
    });

    return {
      loading,
      vtkContainer,
      slice,
      sliceBounds,
      reset,

      // Color legend
      colorLegendStyle,
      imageData,
    };
  },
});
