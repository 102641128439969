




















































































































































/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  ref, toRefs, onMounted, onBeforeUnmount, watch,
  defineComponent,
  computed,
  watchEffect,
  inject,
} from '@vue/composition-api';

import '@kitware/vtk.js/Rendering/Profiles/Geometry';

// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Volume';
import type vtkProp3D from '@kitware/vtk.js/Rendering/Core/Prop3D';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';

import { AxiosInstance } from 'axios';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import {
  useRenderContext,
  setupSlice,
  RenderContext,
  SliceContext,
  resetCamera,
  vtkImagesFromUrls,
  copyVtkImage,
} from '@/utils/vtk';

const DEFAULT_SLICE = 0;
const DEFAULT_SIGMA = 4;
const MAX_SIGMA = 5;
const MIN_SIGMA = 1;
const SIGMA_RANGE = MAX_SIGMA - MIN_SIGMA;

/** Mutates both provided vtk images */
function jacobianDataTransform(vtkImage: vtkImageData, errorMask: vtkImageData) {
  const imageArr: Float32Array = vtkImage.getPointData().getArrayByIndex(0).getData();
  const errorMaskArr: Float32Array = errorMask.getPointData().getArrayByIndex(0).getData();

  // Iterate over every point in source vtkImage
  // Take log of value and set error mask
  imageArr.forEach((val, index) => {
    const logged = Math.log10(val);
    imageArr[index] = logged;
    errorMaskArr[index] = Number(!Number.isFinite(logged) || Number.isNaN(logged));
  });
}

export default defineComponent({
  name: 'FeatureImageViewer',
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    t1AtlasUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { imageUrl, t1AtlasUrl } = toRefs(props);
    const axios = inject<AxiosInstance>('axios');
    if (axios === undefined) {
      throw new Error('Must provide "axios" into component.');
    }

    const loading = ref(true);
    const vtkContainer = ref<HTMLElement | null>(null);
    const renderContext = ref<RenderContext | null>(null);
    const context = ref<SliceContext | null>(null);
    const errorMaskContext = ref<SliceContext | null>(null);
    const atlasContext = ref<SliceContext | null>(null);

    // Sigma slider
    const sigmaVal = ref(DEFAULT_SIGMA);
    const toRange = (x: number) => ((100 / SIGMA_RANGE) * (x - MIN_SIGMA));
    const fromRange = (x: number) => (((SIGMA_RANGE / 100) * x) + MIN_SIGMA);
    const sigmaRange = computed({
      get(): [number, number] {
        const diff = toRange(sigmaVal.value);
        return [100 - diff, 100 + diff];
      },
      set([low, high]: [number, number]) {
        const a = fromRange(Math.abs(low - 100));
        const b = fromRange(Math.abs(high - 100));

        // Choose whichever one changed
        const val = (a === sigmaVal.value ? b : a);
        sigmaVal.value = val;
      },
    });

    // Color legend
    const imageData = computed(() => context.value?.imageData);
    const legendBounds = computed(() => (
      imageData.value
        ? [
          (10 ** (-sigmaVal.value * imageData.value.sigma)).toFixed(2),
          (10 ** (sigmaVal.value * imageData.value.sigma)).toFixed(2),
        ]
        : undefined
    ));
    const colorLegendStyle = computed(() => {
      if (!imageData.value) {
        return undefined;
      }

      // TODO: Determine when to use red for errors
      const stops = 'blue 0%, black 50%, green 100%';
      return {
        'background-image': `linear-gradient(90deg, ${stops}, white 100%)`,
      };
    });

    const slice = ref(DEFAULT_SLICE);
    const sliceDefault = ref(DEFAULT_SLICE);
    const sliceBounds = computed(() => {
      let min = 0;
      let max = 0;

      if (context.value) {
        min = Math.min(min, context.value.actor.getMinZBound());
        max = Math.max(max, context.value.actor.getMaxZBound());
      }

      return {
        min,
        max,
      };
    });

    // Update slice to midpoint of slice bounds
    watch(sliceBounds, (val) => {
      const mid = ((val.max - val.min) / 2) + val.min;
      sliceDefault.value = mid;
      slice.value = mid;
    });

    // Opacity as a percent
    watchEffect(() => {
      if (context.value === null || renderContext.value === null) {
        return;
      }

      const { renderWindow } = renderContext.value;
      renderWindow.render();
    });

    watch(slice, (val) => {
      if (!(
        context.value
        && renderContext.value
        && atlasContext.value
        && errorMaskContext.value
      )) {
        return;
      }

      // Update slices
      const { mapper } = context.value;
      mapper.setZSlice(val);

      const { mapper: errorMapper } = errorMaskContext.value;
      errorMapper.setZSlice(val);

      const { mapper: atlasMapper } = atlasContext.value;
      atlasMapper.setZSlice(val);

      // Render
      const { renderWindow } = renderContext.value;
      renderWindow.render();
    });

    function resetSlice() {
      slice.value = sliceDefault.value;
    }

    // Reset all settings
    function reset() {
      if (renderContext.value === null) {
        return;
      }

      resetCamera(renderContext.value.fullScreenRenderer);
      resetSlice();

      // Reset sigma val
      sigmaVal.value = DEFAULT_SIGMA;
    }

    function colorSlices() {
      if (!context.value || !errorMaskContext.value) {
        return;
      }

      // Jacobian slice
      const { imageData: { sigma } } = context.value;
      const rgb = vtkColorTransferFunction.newInstance();
      rgb.addRGBPoint(-sigmaVal.value * sigma, 0, 0, 1);
      rgb.addRGBPoint(0, 0, 0, 0);
      rgb.addRGBPoint(sigmaVal.value * sigma, 0, 1, 0);
      context.value.actor.getProperty().setUseLookupTableScalarRange(true);
      context.value.actor.getProperty().setRGBTransferFunction(0, rgb);

      const opFun = vtkPiecewiseFunction.newInstance();
      opFun.addPoint(-sigmaVal.value * sigma, 1);
      opFun.addPoint(0, 0);
      opFun.addPoint(sigmaVal.value * sigma, 1);
      context.value.actor.getProperty().setPiecewiseFunction(0, opFun);

      // Error mask slice
      const rgb2 = vtkColorTransferFunction.newInstance();
      rgb2.addRGBPointLong(0, 0, 0, 0, 0.5, 1.0);
      rgb2.addRGBPointLong(1, 1, 0, 0, 0.5, 1.0);
      errorMaskContext.value.actor.getProperty().setUseLookupTableScalarRange(true);
      errorMaskContext.value.actor.getProperty().setRGBTransferFunction(0, rgb2);

      const opFun2 = vtkPiecewiseFunction.newInstance();
      opFun2.addPointLong(0, 0, 0.5, 1.0);
      opFun2.addPointLong(1, 1, 0.5, 1.0);
      errorMaskContext.value.actor.getProperty().setPiecewiseFunction(0, opFun2);
    }

    watch(sigmaVal, () => {
      if (!renderContext.value) {
        return;
      }

      const { renderWindow } = renderContext.value;
      colorSlices();
      renderWindow.render();
    });

    async function init() {
      loading.value = true;

      renderContext.value = useRenderContext(vtkContainer.value, { reset });
      const { renderer } = renderContext.value;

      const [jacobianImage, atlasImage] = await vtkImagesFromUrls(imageUrl.value, t1AtlasUrl.value);
      const errorMask = copyVtkImage(jacobianImage, true);

      // Create vtk images
      jacobianDataTransform(jacobianImage, errorMask);

      atlasContext.value = setupSlice(atlasImage);
      renderer.addActor(atlasContext.value.actor as vtkProp3D as vtkActor);

      // Setup slice contexts
      context.value = setupSlice(jacobianImage);
      renderer.addActor(context.value.actor as vtkProp3D as vtkActor);

      errorMaskContext.value = setupSlice(errorMask);
      renderer.addActor(errorMaskContext.value.actor as vtkProp3D as vtkActor);

      // Add coloring to slice
      colorSlices();

      // Reset camera and Render
      reset();

      loading.value = false;
    }

    onMounted(() => {
      init();
    });

    function deleteSliceContexts() {
      if (context.value) {
        const { actor, mapper } = context.value;
        actor.delete();
        mapper.delete();
        context.value = null;
      }
    }

    function deleteAllContexts() {
      deleteSliceContexts();

      if (renderContext.value) {
        const { fullScreenRenderer } = renderContext.value;
        fullScreenRenderer.delete();
        renderContext.value = null;
      }
    }

    onBeforeUnmount(() => {
      deleteAllContexts();
    });

    // Call update whenever the image url is changed
    watch([imageUrl], () => {
      deleteSliceContexts();
      resetSlice();
      init();
    });

    return {
      loading,
      vtkContainer,
      slice,
      sliceBounds,
      reset,

      // Color legend
      colorLegendStyle,
      legendBounds,
      sigmaRange,
      sigmaVal,
    };
  },
});
