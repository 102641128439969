<template>
  <section>
    <v-app-bar
      color="primary"
      dark
    >
      <HomeLogo to="/" />
      <v-spacer />

      <v-btn
        href="https://github.com/KitwareMedical/UTM"
        target="_blank"
        text
      >
        <v-icon>mdi-github</v-icon>
        <span class="mr-2">View in github</span>
      </v-btn>
    </v-app-bar>
    <v-sheet
      sm="12"
      md="12"
      class="cover-bg"
    >
      <v-row justify="center">
        <v-col
          sm="7"
          md="7"
          class="pl-12 ml-12"
        >
          <h1 class="display-4 font-weight-Medium white--text mb-8">
            UTM Web Tool
          </h1>
          <h1 class="display-1 font-weight-light condensed white--text mb-5">
            Population analysis of neuroimaging datasets via<br>
            Unbalanced Transport Morphometry
          </h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
          sm="7"
          md="7"
          class="pl-12 ml-12"
        >
          <LaunchButtons />
        </v-col>
      </v-row>
    </v-sheet>
  </section>
</template>

<script>
import HomeLogo from '@/components/HomeLogo.vue';
import LaunchButtons from './LaunchButtons.vue';

export default {
  components: {
    LaunchButtons,
    HomeLogo,
  },
};
</script>

<style lang="css" scoped>
.cover-bg {
  /* background: linear-gradient(160deg, #474747 60%, #757575 100%); */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../assets/Features/VBM-vs-UTM.png") no-repeat center;

  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
}
</style>
