<template>
  <div>
    <Hero />
    <About />
    <Diagram />
    <Features />
    <Collaborators />
    <Footer />
  </div>
</template>

<script>
import Hero from './components/Hero.vue';
import About from './components/About.vue';
import Diagram from './components/Diagram.vue';
import Features from './components/Features.vue';
import Collaborators from './components/Collaborators.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'Home',

  components: {
    Hero,
    About,
    Diagram,
    Features,
    Collaborators,
    Footer,
  },
};
</script>
