/* eslint-disable @typescript-eslint/ban-ts-comment */
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow';

// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Volume';
import { readImageArrayBuffer } from 'itk-wasm';

// Fetch the data. Other options include `fetch`, axios.
import vtkLiteHttpDataAccessHelper from '@kitware/vtk.js/IO/Core/DataAccessHelper/LiteHttpDataAccessHelper';

// @ts-ignore
import vtkITKHelper from '@kitware/vtk.js/Common/DataModel/ITKHelper';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';

import vtkITKImageReader from '@kitware/vtk.js/IO/Misc/ITKImageReader';
import vtkImageMapper from '@kitware/vtk.js/Rendering/Core/ImageMapper';
import vtkImageSlice from '@kitware/vtk.js/Rendering/Core/ImageSlice';
import type vtkRenderer from '@kitware/vtk.js/Rendering/Core/Renderer';

// @ts-ignore
import vtkInteractorStyleManipulator from '@kitware/vtk.js/Interaction/Style/InteractorStyleManipulator';
// @ts-ignore
import vtkMouseCameraTrackballZoomToMouseManipulator from '@kitware/vtk.js/Interaction/Manipulators/MouseCameraTrackballZoomToMouseManipulator';
// @ts-ignore
import vtkMouseCameraTrackballPanManipulator from '@kitware/vtk.js/Interaction/Manipulators/MouseCameraTrackballPanManipulator';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import vtkDataSetAttributes from '@kitware/vtk.js/Common/DataModel/DataSetAttributes';

export interface RenderContext {
  fullScreenRenderer: vtkFullScreenRenderWindow;
  renderWindow: vtkRenderWindow;
  renderer: vtkRenderer;
}

export type IComputeHistogram = ReturnType<vtkImageData['computeHistogram']>
export interface SliceContext {
  actor: vtkImageSlice;
  mapper: vtkImageMapper;
  imageData: IComputeHistogram;
}

/* eslint-disable no-bitwise */
export type RGBColor = [number, number, number];
export function hexToRgb(hex: string): RGBColor {
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r / 255, g / 255, b / 255];
}
/* eslint-enable no-bitwise */

/** Return the suffix of an image from the provided URL */
export function imageSuffixFromBlobUrl(url: string): string {
  const { pathname } = new URL(url);

  // Get last part of path
  const name = pathname.split('/').pop();
  if (name === undefined) {
    throw new Error('Unexpected empty path name');
  }

  return name.includes('.nii.gz') ? 'nii.gz' : 'nii';
}

export async function vtkImageDataFromUrl(url: string): Promise<vtkImageData> {
  const imageSuffix = imageSuffixFromBlobUrl(url);

  // @ts-ignore
  const topVolumeArrayBuffer = await vtkLiteHttpDataAccessHelper.fetchBinary(url);
  const { image: itkImage, webWorker } = await readImageArrayBuffer(
    null,
    topVolumeArrayBuffer,
    `brain.${imageSuffix}`,
    `image/${imageSuffix}`,
  );
  webWorker.terminate();

  return vtkITKHelper.convertItkToVtkImage(itkImage) as vtkImageData;
}

export async function vtkImagesFromUrls(...urls: string[]): Promise<vtkImageData[]> {
  return Promise.all(urls.map((url) => vtkImageDataFromUrl(url)));
}

export function copyVtkImage(vtkImage: vtkImageData, clearData = false): vtkImageData {
  // TODO: Do complete clone by deep cloning
  const newImage = vtkImageData.newInstance();
  newImage.setDimensions(vtkImage.getDimensions());
  newImage.setDirection(vtkImage.getDirection());
  newImage.setExtent(vtkImage.getExtent());
  newImage.setOrigin(vtkImage.getOrigin());
  newImage.setSpacing(vtkImage.getSpacing());

  let newDataArray: vtkDataArray;
  const dataArray: vtkDataArray = vtkImage.getPointData().getArrayByIndex(0);
  if (clearData) {
    newDataArray = vtkDataArray.newInstance({
      size: dataArray.getData().length,
      dataType: dataArray.getDataType(),
    });
  } else {
    newDataArray = dataArray.newClone();
    newDataArray.setData(dataArray.getData().slice(0));
  }

  const datasetAttrs = vtkDataSetAttributes.newInstance();
  datasetAttrs.setScalars(newDataArray);
  newImage.setPointData(datasetAttrs);

  return newImage;
}

/**
 *
 * @param vtkImage The vtk image to compute the distribution of.
 * @returns The distribution data.
 */
export function vtkImageDistribution(vtkImage: vtkImageData): IComputeHistogram {
  let maximum = -Infinity;
  let minimum = Infinity;
  let sumOfSquares = 0;
  let isum = 0;
  let inum = 0;

  // Iterate over every value in array
  vtkImage.getPointData().getArrayByIndex(0).getData().forEach((val: number) => {
    const ignore = !Number.isFinite(val) || Number.isNaN(val);
    if (!ignore) {
      if (val > maximum) maximum = val;
      if (val < minimum) minimum = val;
      sumOfSquares += val ** 2;
      isum += val;
      inum += 1;
    }
  });

  // Compute averagem variance and sigma
  const average = inum > 0 ? isum / inum : 0;
  const variance = inum
    ? Math.abs(sumOfSquares / inum - average * average)
    : 0;
  const sigma = Math.sqrt(variance);

  return {
    minimum,
    maximum,
    average,
    variance,
    sigma,
    // count: inum,
  };
}

export function setupSlice(vtkImage: vtkImageData): SliceContext {
  // Set mapper
  const mapper = vtkImageMapper.newInstance();
  mapper.setInputData(vtkImage);

  // Set actor, color window and level
  const actor = vtkImageSlice.newInstance();
  const imageData = vtkImageDistribution(vtkImage);
  const window = imageData.maximum - imageData.minimum;
  actor.getProperty().setColorWindow(window);
  actor.getProperty().setColorLevel(window / 2);

  // Change orientation so that anterior is up
  actor.setOrientation(0, 0, 180);

  // @ts-ignore
  actor.setMapper(mapper);

  return {
    actor,
    mapper,
    imageData,
  };
}

export function resetCamera(fullScreenRenderer: vtkFullScreenRenderWindow) {
  const renderer = fullScreenRenderer.getRenderer();
  const renderWindow = fullScreenRenderer.getRenderWindow();

  renderer.resetCamera();
  renderer.getActiveCamera().zoom(1.4);

  renderWindow.render();
}

export interface RenderContextOptions {
  reset?: (fullScreenRenderer: vtkFullScreenRenderWindow) => unknown,
  usePan?: boolean,
  useZoom?: boolean,
}

export const defaultRenderContextOptions: RenderContextOptions = {
  reset: resetCamera,
  usePan: true,
  useZoom: true,
};

// eslint-disable-next-line max-len
export function setupRenderContext(vtkContainer: unknown, options: RenderContextOptions): RenderContext {
  vtkITKImageReader.setReadImageArrayBufferFromITK(readImageArrayBuffer);
  const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
    // Types on newInstance are missing `rootContainer`
    // @ts-ignore
    rootContainer: vtkContainer,
  });

  // Setup controls
  const interactorStyle = vtkInteractorStyleManipulator.newInstance();

  // Add manipulator for zooming
  if (options.useZoom) {
    const zoomManipulator = vtkMouseCameraTrackballZoomToMouseManipulator.newInstance();
    zoomManipulator.setButton(undefined);
    zoomManipulator.setScrollEnabled(true);
    zoomManipulator.setDragEnabled(false);
    interactorStyle.addMouseManipulator(zoomManipulator);
  }

  // Add manipulator for panning
  if (options.usePan) {
    const panManipulator = vtkMouseCameraTrackballPanManipulator.newInstance();
    panManipulator.setButton(1);
    interactorStyle.addMouseManipulator(panManipulator);
  }

  // Add manipulator for resetting view
  const { reset } = options;
  interactorStyle.addKeyboardManipulator({
    onKeyPress: (i: unknown, r: unknown, key: string) => {
      if (key === 'r' || key === 'R') {
        if (reset !== undefined) {
          reset(fullScreenRenderer);
        }
      }
    },
  });

  // Assign to renderer
  fullScreenRenderer.getInteractor().setInteractorStyle(interactorStyle);

  const renderer = fullScreenRenderer.getRenderer();
  const renderWindow = fullScreenRenderer.getRenderWindow();

  // Set background
  renderer.setBackground(0, 0, 0);

  // Set parallel projection so image doesn't move when slicing
  renderer.getActiveCamera().setParallelProjection(true);

  return {
    fullScreenRenderer,
    renderWindow,
    renderer,
  };
}

export function useRenderContext(
  vtkContainer: unknown,
  options: RenderContextOptions = defaultRenderContextOptions,
) {
  const { fullScreenRenderer, renderWindow, renderer } = setupRenderContext(vtkContainer, options);

  // Trigger resize immediately to avoid showing blank page
  setTimeout(() => { fullScreenRenderer.resize(); }, 0);

  // Delay resize until dialog is fully open
  setTimeout(() => {
    fullScreenRenderer.resize();
  }, 350);

  return {
    fullScreenRenderer,
    renderWindow,
    renderer,
  };
}
