import S3FFClient from 'django-s3-file-field';
import UploadBase from '@girder/components/src/utils/UploadBase';

class BatchCSVUploadHandler extends UploadBase {
  async start() {
    const fd = new FormData();
    fd.append('csvfile', this.file);

    this.progress({ indeterminate: true });

    try {
      return (await this.$rest.post(`datasets/${this.parent.datasetId}/upload_batch`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })).data;
    } catch (err) {
      if (err.response.data) {
        err.response.data = { message: err.response.data[0] };
      }

      throw err;
    }
  }
}

class FileUploadHandler extends UploadBase {
  constructor(file, opts) {
    super(file, opts);
    this.s3FFClient = new S3FFClient({
      baseUrl: `${opts.$rest.defaults.baseURL}/s3-upload`,
      apiConfig: opts.$rest.defaults,
    });
  }

  async start() {
    const resp = await this.$rest.get(`upload/batches/${this.parent.batchId}/pending`, {
      params: {
        name: this.file.name,
      },
    });
    if (resp.data.results.length === 0) {
      // Silently skip this upload
      return {};
    }

    const pendingUpload = resp.data.results[0].id;
    // TODO progress
    const data = await this.s3FFClient.uploadFile(this.file, this.parent.fieldId);

    return (await this.$rest.post('images', {
      pending_upload: pendingUpload,
      blob: data.value,
    })).data;
  }
}

export {
  BatchCSVUploadHandler,
  FileUploadHandler,
};
