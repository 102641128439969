









import TitleBar from '@/components/TitleBar.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    TitleBar,
  },
});
