<template>
  <router-link
    v-slot="{ navigate }"
    :to="to"
    custom
  >
    <div
      style="cursor: pointer"
      @click="navigate"
      @keypress.enter="navigate"
    >
      <v-img
        class="mx-2"
        style="float: left;"
        src="/brain.png"
        max-height="50"
        max-width="60"
      />
      <v-app-bar-title
        class="display-1 py-1"
      >
        UTM
      </v-app-bar-title>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
  },
};
</script>
