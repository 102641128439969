































































































































import {
  computed, defineComponent, inject, onMounted, ref, toRefs,
} from '@vue/composition-api';
import { Dataset } from '@/types';
import { AxiosInstance } from 'axios';
import { sleep, statusToIcon } from '@/utils';

export default defineComponent({
  props: {
    datasetId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { datasetId } = toRefs(props);
    const axios = inject('axios') as AxiosInstance;

    const loading = ref(true);
    const dataset = ref<Dataset | null>(null);
    const resultLink = computed(() => dataset.value?.current_analysis_result?.zip_file);
    const preprocessingComplete = computed(() => dataset.value && dataset.value.current_preprocessing_batch?.status === 'Finished');
    const analysisResult = computed(() => dataset.value?.current_analysis_result || null);
    const analysisRunning = computed(() => analysisResult.value && analysisResult.value.status === 'Running');
    const analysisComplete = computed(() => analysisResult.value && (
      analysisResult.value.status === 'Finished'
      || analysisResult.value.status === 'Failed'
    ));

    const showAnalysisErrorMessage = ref(false);
    const statusIcons = computed(() => {
      if (!analysisResult.value) {
        return [undefined, undefined];
      }

      return statusToIcon(analysisResult.value.status);
    });

    async function refresh() {
      loading.value = true;
      const resp = await axios.get(`datasets/${datasetId.value}`);
      dataset.value = resp.data;
      loading.value = false;
    }

    async function poll() {
      await refresh();
      while (!analysisComplete.value) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(5000);

        // eslint-disable-next-line no-await-in-loop
        await refresh();
      }
    }

    async function run() {
      await axios.post(`datasets/${datasetId.value}/utm_analysis`);
      poll();
    }

    onMounted(() => {
      refresh();
    });

    return {
      loading,
      dataset,
      resultLink,
      preprocessingComplete,
      analysisResult,
      analysisRunning,
      analysisComplete,
      showAnalysisErrorMessage,
      statusIcons,
      refresh,
      run,
    };
  },
});
