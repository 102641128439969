

























































































































































import {
  computed,
  defineComponent, inject, ref, toRefs, watch,
} from '@vue/composition-api';
import { AxiosInstance } from 'axios';
import {
  Analysis, AnalysisFeature, Atlas, Paginated,
} from '@/types';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vtkProxyManager from '@kitware/vtk.js/Proxy/Core/ProxyManager';
import type { Vector3 } from '@kitware/vtk.js/types';

import { copyVtkImage, vtkImageDataFromUrl } from '@/utils/vtk';
import { SliceAxis } from '@/types/vtk';
import SliceViewer from './SliceViewer.vue';
import { proxyConfiguration, setupProxyManagerViews } from './proxy';
import { useLocation } from './state';

const analyses: AnalysisFeature[] = ['allocation', 'transport', 'vbm'];
const axisOptions: SliceAxis[] = ['X', 'Y', 'Z'];

// Slider constants
const granularityPerLabel = 5;
const numSliderLabels = 8;
const ExponentialDisplayThreshold = 0.0005;

// Add extra tick so both ends have a value
const numSliderTicks = (numSliderLabels * granularityPerLabel) + 1;
const formatNumber = (x: number) => (
  (Math.abs(x) < ExponentialDisplayThreshold && x !== 0) ? x.toExponential(1) : x.toPrecision(2)
);

// Create labels based on number of ticks
const sliderTickLabels = Array.from(Array(numSliderTicks)).map(
  (_, i) => {
    if (i % granularityPerLabel !== 0) {
      return '';
    }

    const labelIndex = i / granularityPerLabel;
    return formatNumber((0.05 / 10 ** labelIndex));
  },
).reverse();

// Slider / pvalue conversion
const PValueFloor = Number(sliderTickLabels[0]);
const PValueCeil = Number(sliderTickLabels[sliderTickLabels.length - 1]);
const PRangeRatio = PValueCeil / PValueFloor;
const sliderRatioToPValue = (x: number) => PValueFloor * Math.exp(Math.log(PRangeRatio) * x);

export default defineComponent({
  name: 'Analysis',
  components: {
    SliceViewer,
    // Slider,
  },
  props: {
    analysisId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const axios = inject('axios') as AxiosInstance;

    const { analysisId } = toRefs(props);
    const analysis = ref<Analysis | null>(null);
    async function fetchAnalysis() {
      const resp = await axios.get<Analysis>(`analysis/${analysisId.value}`);
      analysis.value = resp.data;
    }

    // Setup main proxy manager and views
    const proxyManager = vtkProxyManager.newInstance({ proxyConfiguration });
    const views = setupProxyManagerViews(proxyManager);
    const showViewers = ref(false);

    /** Ensure all views have a representation for each source (per view) */
    function updateViewRepresentations() {
      proxyManager.getSources().forEach((s: unknown) => {
        proxyManager.createRepresentationInAllViews(s);
      });
      proxyManager.renderAllViews();
    }

    // Misc
    const variables = computed(() => Object.keys(analysis.value?.data || {}));
    const selectedVariable = ref<string | null>(null);
    const selectedFeature = ref<AnalysisFeature | null>(null);
    const dataSelected = computed(() => (
      analysis.value && selectedVariable.value && selectedFeature.value
    ));

    // Core image data
    const imageDataUrls = computed(() => {
      if (
        analysis.value === null
        || selectedVariable.value === null
        || selectedFeature.value === null
      ) {
        return null;
      }

      return analysis.value?.data[selectedVariable.value][selectedFeature.value];
    });
    const imageData = ref<vtkImageData | null>(null);
    const imageDataPValue = ref<vtkImageData | null>(null);
    // The min/max values found in the image data
    const imageDataBounds = ref([-Infinity, Infinity] as [number, number]);
    const sourceProxy = proxyManager.createProxy(
      'Sources',
      'TrivialProducer',
    );
    sourceProxy.setName('Image');

    // Atlas data
    const atlasImageData = ref<vtkImageData | null>(null);
    const atlasSourceProxy = proxyManager.createProxy(
      'Sources',
      'TrivialProducer',
    );
    atlasSourceProxy.setName('Atlas');

    const atlas = ref<Atlas | null>(null);
    const atlasLoading = ref(false);
    async function fetchAtlas() {
      atlasLoading.value = true;
      const resp = await axios.get<Paginated<Atlas>>('atlases', { params: { name: 'T1.nii.gz' } });
      if (resp.data.count === 0) {
        throw new Error('Could not fetch T1 Atlas.');
      }

      [atlas.value] = resp.data.results;
      atlasImageData.value = await vtkImageDataFromUrl(atlas.value.blob);
      atlasSourceProxy.setInputData(atlasImageData.value);
      updateViewRepresentations();

      atlasLoading.value = false;
    }

    function alignImageWithAtlas(image: vtkImageData) {
      if (atlasImageData.value === null) {
        throw new Error('Null atlas data!');
      }

      // Match origins
      const atlasImage = atlasImageData.value;
      image.setOrigin(atlasImage.getOrigin());

      // Scale atlas to match image
      const imageDims = image.getDimensions();
      const atlasDims = atlasImage.getDimensions();
      const scale = imageDims.map((x, i) => x / atlasDims[i]) as Vector3;
      atlasImage.setSpacing(scale);

      // Align direction
      image.setDirection(atlasImage.getDirection());
    }

    // The raw value from the slider, which will be mapped to the log scale
    const pvalueSlider = ref(numSliderTicks - 1);
    const pvalueThresholdEnabled = ref(true);
    const pvalueThreshold = computed(() => {
      if (imageDataPValue.value === null) {
        return PValueCeil;
      }

      const ratio = pvalueSlider.value / (numSliderTicks - 1);
      return sliderRatioToPValue(ratio);
    });

    /** Returns a data array filtered by the current pvalue threshold. */
    function filterImageDataByPValue(): vtkImageData {
      if (imageData.value === null || imageDataPValue.value === null) {
        throw new Error('Image data or pvalue data not set!');
      }

      // Copy imageData
      const newImage = copyVtkImage(imageData.value);
      if (!pvalueThresholdEnabled.value) {
        return newImage;
      }

      // The raw data from the pvalue image we'll be comparing
      const pValueData: Float64Array = imageDataPValue.value
        .getPointData()
        .getArrayByIndex(0)
        .getData();

      // Filter values by threshold
      const data: Float64Array = newImage.getPointData().getArrayByIndex(0).getData();
      data.forEach((_, index) => {
        // Set zero if pvalue at this index is greater than allowed
        if (pValueData[index] > pvalueThreshold.value) {
          data[index] = 0;
        }
      });

      return newImage;
    }

    function getSymmetricDataBounds(image: vtkImageData): [number, number] {
      const [minVal, maxVal] = image.getPointData().getArrayByIndex(0).getRange();
      const absMax = Math.max(Math.abs(minVal), Math.abs(maxVal));
      return [-1 * absMax, absMax];
    }

    // Update the image data based on url change
    watch(imageDataUrls, async (val) => {
      if (val === null) {
        return;
      }

      // Convert urls to images
      [imageData.value, imageDataPValue.value] = [
        await vtkImageDataFromUrl(val.correlation),
        await vtkImageDataFromUrl(val.pvalue),
      ];

      // Set bounds
      imageDataBounds.value = getSymmetricDataBounds(imageData.value);

      // Align image with atlas
      alignImageWithAtlas(imageData.value);

      // Set source input data
      const filteredData = filterImageDataByPValue();
      sourceProxy.setInputData(filteredData);
      updateViewRepresentations();

      // Ensure views are now shown if not already
      showViewers.value = true;
    });

    // Any time the slider is moved or thresholding is toggled, update input data
    watch([pvalueThreshold, pvalueThresholdEnabled], () => {
      // Update bounds of image data
      imageDataBounds.value = getSymmetricDataBounds(sourceProxy.getDataset());
      sourceProxy.setInputData(filterImageDataByPValue());
    });

    // Since all of the sources, views, and representations haven't been fully initialized yet,
    // Update these refs only once, when they are finally initialized.
    let locationLoaded = false;
    let iSlice = ref(0);
    let jSlice = ref(0);
    let kSlice = ref(0);
    sourceProxy.onModified(() => {
      if (locationLoaded) { return; }

      try {
        const loc = useLocation(proxyManager);
        iSlice = loc.iSlice;
        jSlice = loc.jSlice;
        kSlice = loc.kSlice;
        locationLoaded = true;
      } finally {
        // Do nothing
      }
    });

    // Compute the current correlation and p-value based on the slice indices
    type ValueMap = Record<'correlation' | 'pvalue', number>;
    const currentValues = computed((): ValueMap => {
      let correlation = 0;
      let pvalue = 0;

      // Only update if data has been loaded
      if (imageData.value && imageDataPValue.value) {
        const index = imageData.value.getOffsetIndexFromWorld(
          [iSlice.value, jSlice.value, kSlice.value],
        );
        correlation = imageData.value.getPointData().getArrayByIndex(0).getData()[index];
        pvalue = imageDataPValue.value.getPointData().getArrayByIndex(0).getData()[index];
      }

      return { correlation, pvalue };
    });

    // Fetch analysisand atlas on created
    fetchAnalysis();
    fetchAtlas();

    return {
      variables,
      analyses,
      selectedVariable,
      selectedFeature,
      dataSelected,
      imageData,
      imageDataPValue,
      imageDataBounds,
      atlasImageData,
      atlasLoading,
      pvalueSlider,
      pvalueThreshold,
      axisOptions,
      views,
      proxyManager,
      numSliderTicks,
      sliderTickLabels,
      pvalueThresholdEnabled,
      currentValues,
      formatNumber,
      showViewers,
    };
  },
});
