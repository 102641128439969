import { render, staticRenderFns } from "./RegisteredImageViewer.vue?vue&type=template&id=a4dd9302&scoped=true&"
import script from "./RegisteredImageViewer.vue?vue&type=script&lang=ts&"
export * from "./RegisteredImageViewer.vue?vue&type=script&lang=ts&"
import style0 from "./RegisteredImageViewer.vue?vue&type=style&index=0&id=a4dd9302&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4dd9302",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VSlider,VSubheader,VSwitch})
